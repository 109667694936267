import { Inject, Injectable } from '@angular/core';
import { AssetRaw } from '@remberg/assets/common/main';
import { ASSETS_OFFLINE_SERVICE, AssetsOfflineServiceInterface } from '@remberg/assets/ui/clients';
import { StepsProvider } from '@remberg/forms/common/main';
import { Address } from '@remberg/global/common/core';
import { PrefillingStepFunctions } from './interfaces';

@Injectable()
export class AssetPrefillingStepsProvider implements StepsProvider<PrefillingStepFunctions> {
  constructor(
    @Inject(ASSETS_OFFLINE_SERVICE)
    private readonly assetsService: AssetsOfflineServiceInterface,
  ) {}

  public getSteps(): Partial<PrefillingStepFunctions> {
    return {
      assetIdToAsset: (id: string | undefined) => this.loadAsset(id),
      assetToParentAssetId,
      assetToCustomerPersonUserId,
      assetToManufacturerPersonUserId,
      assetToAssetTypeNameString,
      assetToLocationAddress,
      assetToCustomerAccountId,
      assetToCustomPropertyValue,
    };
  }

  public async loadAsset(id: string | undefined): Promise<AssetRaw | undefined> {
    return id ? await this.assetsService.tryGetInstance(id) : undefined;
  }
}

// custom properties are not supported in offline mode yet
// they need to be synced and displayed first
// frontend/src/app/ionic/services/assets.offline.service.ts
function assetToCustomPropertyValue(
  asset: AssetRaw | undefined,
  propertyId: unknown | undefined,
): unknown | undefined {
  return undefined;
}

function assetToAssetTypeNameString(asset: AssetRaw | undefined): string | undefined {
  return asset?.assetTypeLabel;
}

function assetToCustomerAccountId(asset: AssetRaw | undefined): string | undefined {
  return asset?.relatedOrganizationIds?.[0];
}

function assetToLocationAddress(asset: AssetRaw | undefined): Address | undefined {
  return asset?.location;
}

function assetToParentAssetId(asset: AssetRaw | undefined): string | undefined {
  return asset?.parentId;
}

function assetToCustomerPersonUserId(asset: AssetRaw | undefined): string | undefined {
  return asset?.relatedContactIds?.[0];
}

function assetToManufacturerPersonUserId(asset: AssetRaw | undefined): string | undefined {
  return asset?.tenantOwnerContactId;
}
