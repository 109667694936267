import { AssetType, AssetTypeBasic } from '@remberg/assets/common/base';

export function mapAssetTypeToAssetTypeBasic(assetType: AssetType): AssetTypeBasic;
export function mapAssetTypeToAssetTypeBasic(
  assetType: AssetType | undefined,
): AssetTypeBasic | undefined {
  return assetType
    ? { _id: assetType._id, label: assetType.label, imageId: assetType.imageId }
    : undefined;
}
