import { Injectable } from '@angular/core';
import {
  AdvancedFilterConcatOperatorEnum,
  AdvancedFilterOperatorEnum,
} from '@remberg/advanced-filters/common/main';
import { ContactBasic } from '@remberg/crm/common/base';
import { ContactSortFieldEnum, ContactsFilterEnum } from '@remberg/crm/common/main';
import { ContactsService } from '@remberg/crm/ui/clients';
import { SortDirectionEnum, getNumberCount } from '@remberg/global/common/core';
import { DataSourceUpdatePayload, InfiniteListContainerStore } from '@remberg/global/ui';
import { Observable, of, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ChipListContactBasicContainerStore extends InfiniteListContainerStore<ContactBasic> {
  public searchValue: string = '';
  public prependedDataSource: ContactBasic[] = [];
  public selectedContactIds: string[] = [];
  public whitelistedDomains: string[] = [];

  constructor(private readonly contactsService: ContactsService) {
    super();
  }

  public fetchFunction$(
    batchSize: number,
    currentBatch: number,
    isFetchingDisabled: boolean,
  ): Observable<DataSourceUpdatePayload<ContactBasic>> {
    if (isFetchingDisabled) {
      return of({ dataSource: [], totalCount: 0, currentBatch: 0 });
    }
    const filters = [];
    if (this.whitelistedDomains.length) {
      filters.push({
        identifier: ContactsFilterEnum.EMAIL_WHITELIST,
        value: this.whitelistedDomains.join('|'),
        operator: AdvancedFilterOperatorEnum.CONTAINS,
      });
    }
    return this.contactsService
      .findManyWithCountBasic({
        search: this.searchValue,
        filterObject: {
          concatOperator: AdvancedFilterConcatOperatorEnum.AND,
          filters,
        },
        page: currentBatch,
        limit: batchSize,
        sortDirection: SortDirectionEnum.ASC,
        sortField: ContactSortFieldEnum.LAST_NAME,
      })
      .pipe(
        withLatestFrom(this.dataSource$),
        map(([{ contacts, count }, dataSource]) =>
          this.createDataSourceState(dataSource, contacts, count, currentBatch, this.searchValue),
        ),
      );
  }

  filterInitialDataSourceByEmail(searchValue: string): ContactBasic[] {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    return this.prependedDataSource.filter(
      (contact) =>
        contact.primaryEmail?.toLowerCase().includes(lowerCaseSearchValue) ||
        contact.firstName?.toLowerCase().includes(lowerCaseSearchValue) ||
        contact.lastName?.toLowerCase().includes(lowerCaseSearchValue),
    );
  }

  filterOutIncludedContacts(
    dataSource: ContactBasic[],
    staticDataSource: ContactBasic[],
  ): ContactBasic[] {
    const initialDataSourceIds = staticDataSource.map((contact) => contact._id);
    return dataSource.filter((contact) => !initialDataSourceIds.includes(contact._id));
  }

  private createDataSourceState(
    dataSource: ContactBasic[],
    nextPageItems: ContactBasic[],
    totalCount: number | undefined,
    currentBatch: number,
    searchValue: string,
  ): DataSourceUpdatePayload<ContactBasic> {
    const filteredInitialDataSource = this.filterInitialDataSourceByEmail(searchValue);
    const filteredNextPageItems = this.filterOutIncludedContacts(
      nextPageItems,
      filteredInitialDataSource,
    );
    let modifiedDataSource = [...dataSource, ...filteredNextPageItems];
    if (!dataSource.length) {
      modifiedDataSource = [...(filteredInitialDataSource ?? []), ...modifiedDataSource];
    }

    const modifiedCount = getNumberCount(totalCount ?? 0);
    return {
      dataSource: modifiedDataSource,
      totalCount: modifiedCount,
      ...(currentBatch && { currentBatch: ++currentBatch }),
      overwriteDataSource: true,
    };
  }
}
