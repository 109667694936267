import { CustomPropertyValue } from '@remberg/custom-properties/common/main';

// stuff below is duplicated from libs/forms/api/server/src/common/models/temporal.ts

export enum CustomPropertyTypesEnum {
  INPUT_TEXT = 'INPUT_TEXT',
  TEXTAREA = 'TEXTAREA',
  CHECKBOX = 'CHECKBOX',
  INPUT_NUMBER = 'INPUT_NUMBER',
  URL = 'URL',
  SELECT = 'SELECT',
  REMBERG_ASSET_SELECT = 'REMBERG_ASSET_SELECT', // asset-select.component
  REMBERG_CUSTOMER_SELECT = 'REMBERG_CUSTOMER_SELECT', // customer-select.component
  REMBERG_USER_SELECT = 'REMBERG_USER_SELECT', // user-select.component
  REMBERG_DATE = 'REMBERG_DATE', // date.component
  REMBERG_DATETIME = 'REMBERG_DATETIME', // datetime.component
  REMBERG_ORGANIZATION_SELECT = 'REMBERG_ORGANIZATION_SELECT',
  REMBERG_CONTACT_SELECT = 'REMBERG_CONTACT_SELECT',
}

// CustomPropertyValue in libs/forms/api/server/src/common/models/temporal.ts
export interface TemporalCustomPropertyValue {
  propertyId: number | string;
  property: CustomProperty;
  value: string | string[] | number | boolean;
}

export interface CustomProperty {
  _id: string;
  fieldType: CustomPropertyTypesEnum;
}

const mapValueToLegacyValue = (
  customPropertyValue: CustomPropertyValue<false>,
): string | string[] | number | boolean => {
  switch (customPropertyValue.propertyType) {
    case CustomPropertyTypesEnum.REMBERG_ASSET_SELECT:
    case CustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT:
    case CustomPropertyTypesEnum.REMBERG_CONTACT_SELECT:
      return customPropertyValue.value?.[0] ?? '';
    default:
      return customPropertyValue.value;
  }
};

export function mapCustomPropertyValueToTemporalOne(
  customPropertyValue: CustomPropertyValue<false> | undefined,
): TemporalCustomPropertyValue | undefined {
  if (!customPropertyValue) return undefined;

  return {
    propertyId: customPropertyValue.externalReference,
    property: {
      _id: customPropertyValue.customPropertyId,
      // Be aware that the prefilling will not work for a new added propertyType that can't be mapped to an old type
      fieldType: customPropertyValue.propertyType,
    },
    value: mapValueToLegacyValue(customPropertyValue),
  };
}
