import { Inject, Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Platform, isPlatform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  ASSETS_OFFLINE_SERVICE,
  ASSET_TYPES_OFFLINE_SERVICE,
  AssetTypesOfflineServiceInterface,
  AssetsOfflineServiceInterface,
} from '@remberg/assets/ui/clients';
import {
  CONTACTS_OFFLINE_SERVICE,
  ContactsOfflineServiceInterface,
  ORGANIZATIONS_OFFLINE_SERVICE,
  OrganizationOfflineServiceInterface,
} from '@remberg/crm/ui/clients';
import {
  FilesystemService,
  PLATFORM_FILES_OFFLINE_SERVICE,
  PlatformFilesOfflineServiceInterface,
} from '@remberg/files/ui/clients';
import {
  FORM_INSTANCE_OFFLINE_SERVICE,
  FORM_TEMPLATE_OFFLINE_SERVICE,
  FormInstanceOfflineServiceInterface,
  FormTemplateOfflineServiceInterface,
} from '@remberg/forms/ui/clients';
import { ADMIN_SUBDOMAIN } from '@remberg/global/common/core';
import { VERSION } from '@remberg/global/common/version';
import {
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  DEFAULT_DIALOG_WIDTH,
  LocalStorageKeys,
  LogService,
  isNotAReservedSubdomain,
  setupQuill,
} from '@remberg/global/ui';
import { PARTS_OFFLINE_SERVICE, PartsOfflineServiceInterface } from '@remberg/parts/ui/clients';
import { TenantPublic, tenantToTenantPublic } from '@remberg/tenants/common/main';
import {
  SERVICE_CASE_OFFLINE_SERVICE,
  ServiceCaseOfflineServiceInterface,
} from '@remberg/tickets/ui/clients';
import {
  WORK_ORDER_OFFLINE_SERVICE,
  WORK_ORDER_STATUS_OFFLINE_SERVICE,
  WORK_ORDER_TYPE_OFFLINE_SERVICE,
  WorkOrderOfflineServiceInterface,
  WorkOrderStatusOfflineServiceInterface,
  WorkOrderTypeOfflineServiceInterface,
} from '@remberg/work-orders-legacy/ui/clients';
import {
  WORK_ORDER_2_OFFLINE_SERVICE,
  WORK_ORDER_STATUS_2_OFFLINE_SERVICE,
  WORK_ORDER_TYPE_2_OFFLINE_SERVICE,
  WorkOrder2OfflineServiceInterface,
  WorkOrderStatus2OfflineServiceInterface,
  WorkOrderType2OfflineServiceInterface,
} from '@remberg/work-orders/ui/clients';
import * as Bowser from 'bowser';
import { catchError, exhaustMap, filter, firstValueFrom, from, map, of, take, tap } from 'rxjs';
import { DialogOptions } from '../../dialogs/dialogs';
import { DynamicPopUpComponent } from '../../dialogs/dynamic-pop-up/dynamic-pop-up.component';
import { TenantService } from '../../services/api/tenant.service';
import { AppStateService } from '../../services/app-state.service';
import { DeviceInfoService } from '../../services/device-info.service';
import { DialogService } from '../../services/dialog.service';
import { MobileLogsService } from '../../services/mobile-logs/mobile-logs.service';
import { EmailStatusOfflineService } from '../../services/offline/emailstatus.offline.service';
import { FormTemplateVersionOfflineService } from '../../services/offline/formTemplateVersion.offline.service';
import { UserGroupOfflineService } from '../../services/offline/userGroup.offline.service';
import { PersistedStateService } from '../../services/persisted-state.service';
import { ServerConfigurationService } from '../../services/server-configuration.service';
import { SqlDBService } from '../../services/sqlDB.service';
import { SqlDBMockService } from '../../services/sqlite-mock/sqlDBMock.service';
import { SyncUiService } from '../../services/sync-ui.service';
import { ThemeService } from '../../services/theme.service';
import { RootGlobalState } from '../core-ui.definitions';
import { GlobalActions } from './global.actions';
import { DeviceTypeState, UIState, VersionInfoState } from './global.definitions';
import { GlobalSelectors } from './global.selectors';

@Injectable()
export class GlobalInitializationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: LogService,
    private readonly store: Store<RootGlobalState>,
    private readonly appState: AppStateService,
    private readonly platform: Platform,
    private readonly filesSystemService: FilesystemService,
    private readonly dbService: SqlDBService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly serverConfigurationService: ServerConfigurationService,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly themeService: ThemeService,
    private readonly mobileLogsService: MobileLogsService,
    @Inject(ASSETS_OFFLINE_SERVICE)
    private readonly assetsOfflineService: AssetsOfflineServiceInterface,
    @Inject(ASSET_TYPES_OFFLINE_SERVICE)
    private readonly assetTypesOfflineService: AssetTypesOfflineServiceInterface,
    @Inject(CONTACTS_OFFLINE_SERVICE)
    private readonly contactOfflineService: ContactsOfflineServiceInterface,
    private readonly emailStatusOfflineService: EmailStatusOfflineService,
    @Inject(PLATFORM_FILES_OFFLINE_SERVICE)
    private readonly platformFilesOfflineService: PlatformFilesOfflineServiceInterface,
    @Inject(FORM_INSTANCE_OFFLINE_SERVICE)
    private readonly formInstanceOfflineService: FormInstanceOfflineServiceInterface,
    @Inject(FORM_TEMPLATE_OFFLINE_SERVICE)
    private readonly formTemplateOfflineService: FormTemplateOfflineServiceInterface,
    private readonly formTemplateVersionOfflineService: FormTemplateVersionOfflineService,
    @Inject(ORGANIZATIONS_OFFLINE_SERVICE)
    private readonly organizationOfflineService: OrganizationOfflineServiceInterface,
    @Inject(PARTS_OFFLINE_SERVICE)
    private readonly partsOfflineService: PartsOfflineServiceInterface,
    @Inject(SERVICE_CASE_OFFLINE_SERVICE)
    private readonly serviceCaseOfflineService: ServiceCaseOfflineServiceInterface,
    private readonly userGroupOfflineService: UserGroupOfflineService,
    @Inject(WORK_ORDER_OFFLINE_SERVICE)
    private readonly workOrderOfflineService: WorkOrderOfflineServiceInterface,
    @Inject(WORK_ORDER_STATUS_OFFLINE_SERVICE)
    private readonly workOrderStatusOfflineService: WorkOrderStatusOfflineServiceInterface,
    @Inject(WORK_ORDER_TYPE_OFFLINE_SERVICE)
    private readonly workOrderTypeOfflineService: WorkOrderTypeOfflineServiceInterface,
    @Inject(WORK_ORDER_2_OFFLINE_SERVICE)
    private readonly workOrder2OfflineService: WorkOrder2OfflineServiceInterface,
    @Inject(WORK_ORDER_STATUS_2_OFFLINE_SERVICE)
    private readonly workOrderStatus2OfflineService: WorkOrderStatus2OfflineServiceInterface,
    @Inject(WORK_ORDER_TYPE_2_OFFLINE_SERVICE)
    private readonly workOrderType2OfflineService: WorkOrderType2OfflineServiceInterface,
    private readonly syncUIService: SyncUiService,
    private readonly sqlDBMockService: SqlDBMockService,
    private readonly _dialog: DialogService,
    private readonly tenantService: TenantService,
    private readonly persistedStateService: PersistedStateService<RootGlobalState>,
  ) {}

  public readonly globalInitialization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.startGlobalInitialization),
      exhaustMap(() => from(this.initializeApp())),
      map(() => GlobalActions.globalInitializationComplete()),
      catchError((error) => of(GlobalActions.globalInitializationError({ error }))),
    ),
  );

  public readonly globalInitializationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.globalInitializationError),
        tap((action) => this.showErrorPopup(action.error)),
      ),
    { dispatch: false },
  );

  private async initializeApp(): Promise<void> {
    this.logger.info()('Starting Global Initialization');
    // DeviceInfo & Mobile Logs need to be set up first in order to log errors in following steps correctly
    await this.platform.ready();
    const deviceType = await this.deviceInfoService.initialize();
    await this.mobileLogsService.initialize();

    // Fundamental Services & State
    this.logger.info()('Starting appState initialization');
    await this.appState.initialize();
    this.logger.info()('Starting versionInfo initialization');
    await this.initializeVersionInfo(deviceType);
    this.logger.info()('Starting connectivityService initialization');
    await this.connectivityService.initialize(); // TODO: move state to NgRX
    this.logger.info()('Starting serverConfiguration initialization');
    await this.serverConfigurationService.initialize(); // TODO: move state to NgRX

    this.logger.info()('Starting applicationDomain initialization');
    await this.initializeApplicationDomain();
    await this.waitUntilBreakpointsInitialized();

    // Starting from here, HTTP calls are allowed!

    // Setup Quill
    this.logger.info()('Starting setupQuill Initialization');
    setupQuill();

    // Restore DataFrom LocalStorage

    // Ionic & Mobile
    this.logger.info()('Starting setupIonic Initialization');
    await this.setupIonic(deviceType);

    // User Session
    const token = this.appState.getValue(LocalStorageKeys.TOKEN);
    if (token) {
      this.store.dispatch(GlobalActions.startRegisterSessionInfoAppInitialization({ token }));
      // Case 1: registerSuccessFul --> continue
      // Case 2: user logged out after invalid token --> continue
      // Case 3: unintentional error during the register process --> throw error

      const initializationError = await firstValueFrom(
        this.actions$.pipe(
          ofType(
            GlobalActions.completeRegisterSessionInfo,
            GlobalActions.logoutComplete,
            GlobalActions.registerSessionInfoError,
          ),
          take(1),
          map((action) =>
            action.type === GlobalActions.registerSessionInfoError.type ? action.error : undefined,
          ),
        ),
      );
      if (initializationError) {
        const hasUserChosenToLogout = await firstValueFrom(
          this.store.select(GlobalSelectors.selectHasUserChosenToLogout),
        );
        if (!hasUserChosenToLogout) {
          throw initializationError;
        }
      }

      // Restore local UI state (e.g. isPrimaryNavBarOpen)
      await this.initializeLocalUIStateFromLocalStorage();
    } else {
      this.logger.debug()('Initialize WITHOUT token');
    }

    this.logger.info()('Starting tenant Initialization');

    // The TenantPublic is initialized after the SessionInfo has been registered as this allows us to
    // reuse the full Tenant from the SessionInfo instead of making a new request.
    await this.initializeTenantPublic();

    // Theme (depends on session info to load the correct Theme)
    await this.themeService.initialize(deviceType);

    this.persistedStateService.registerPersistSubscriptions([
      { key: LocalStorageKeys.UI_STATE, selector: GlobalSelectors.selectLocalUIState },
      { key: LocalStorageKeys.TOKEN, selector: GlobalSelectors.selectToken },
      { key: LocalStorageKeys.IONIC_PUBLIC_TENANT, selector: GlobalSelectors.selectTenantPublic },
      { key: LocalStorageKeys.IONIC_CURRENT_TENANT, selector: GlobalSelectors.selectTenant },
      {
        key: LocalStorageKeys.IONIC_CURRENT_REMBERG_USER,
        selector: GlobalSelectors.selectCurrentRembergUser,
      },
      { key: LocalStorageKeys.IONIC_CURRENT_USER_ROLE, selector: GlobalSelectors.selectUserRole },
      { key: LocalStorageKeys.IONIC_CURRENT_SERVER_URL, selector: GlobalSelectors.selectServerUrl },
      {
        key: LocalStorageKeys.IONIC_CURRENT_SERVER_NAME,
        selector: GlobalSelectors.selectServerName,
      },
    ]);
  }

  private async initializeVersionInfo(deviceType: DeviceTypeState): Promise<void> {
    const pipelineMeta = window.__env;
    this.logger.info()('PipelineMeta', pipelineMeta);

    let versionInfoString: string;
    let additionalVersionInfo = '';
    if (deviceType?.isIonic) {
      await this.platform.ready();
      if (!deviceType.simulatedIonicType) {
        const info = await App.getInfo();
        const version = info.version;
        const buildInfo = info.build; // hack for the beta app to show the version code
        const platform = isPlatform('android')
          ? 'Android'
          : isPlatform('ios')
            ? 'iOS'
            : 'Unknown Device';
        versionInfoString = `${version}-${buildInfo} ${platform}`;
      } else {
        versionInfoString = `${VERSION}-${pipelineMeta?.commitId} simulatedIonic`;
      }
    } else {
      const commitId = pipelineMeta?.commitId;
      const buildTime = pipelineMeta?.buildTime;
      const browser = Bowser.getParser(window.navigator.userAgent)?.parseBrowser();
      const browserName = browser ? browser.name + ' [' + browser.version + ']' : 'Unknown Browser';
      versionInfoString = `${VERSION}-${commitId} Web`;
      additionalVersionInfo = `(${buildTime}) - ${browserName}`;
    }

    const versionInfo: VersionInfoState = {
      pipelineMeta: pipelineMeta,
      versionInfoString: versionInfoString,
      additionalVersionInfo: additionalVersionInfo,
    };
    this.logger.info()('VersionInfo', versionInfo);
    this.store.dispatch(
      GlobalActions.versionInfoInitialized({
        versionInfo,
      }),
    );
    await firstValueFrom(
      this.store.select(GlobalSelectors.selectVersionInfo).pipe(filter(Boolean)),
    );
  }

  private async initializeTenantPublic(): Promise<void> {
    // 1. derive the TenantPublic
    //   --> from full Tenant if available
    //   --> fetch by subdomain
    //      --> if not found, redirect to applicationRoot
    //   --> from localstorage as last resort, but only for isIonic
    let tenantPublic: TenantPublic | undefined;
    const tenantSubdomain = await firstValueFrom(
      this.store.select(GlobalSelectors.selectTenantSubdomain),
    );
    const tenant = await firstValueFrom(this.store.select(GlobalSelectors.selectTenant));
    if (tenant) {
      tenantPublic = tenantToTenantPublic(tenant);
    } else if (tenantSubdomain && tenantSubdomain !== ADMIN_SUBDOMAIN) {
      try {
        tenantPublic = await firstValueFrom(
          this.tenantService.getPublicBySubdomain(tenantSubdomain),
        );
      } catch (error) {
        if ((error as any).status !== 404) {
          this.logger.error()('Error fetching public Tenant', error);
          throw error;
        }
      }
    }
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    if (!tenantPublic && isIonic) {
      const rawTenantPublic = this.appState.getValue(LocalStorageKeys.IONIC_PUBLIC_TENANT);
      tenantPublic = rawTenantPublic ? JSON.parse(rawTenantPublic) : undefined;
    }

    // 2. set TenantPublic info in store
    //   --> wait for it to be set before continuing
    if (tenantPublic) {
      this.store.dispatch(GlobalActions.setTenantPublic({ tenantPublic }));
      await firstValueFrom(
        this.store.select(GlobalSelectors.selectTenantPublic).pipe(filter(Boolean)),
      );
    }
  }

  private async initializeApplicationDomain(): Promise<void> {
    const host = window.location.host;
    this.logger.debug()('Initializing ApplicationDomain on host: ' + host);
    const parts = host.split('.');
    this.logger.debug()('Domain parts:', JSON.stringify(parts));

    let applicationRootDomain: string;
    let tenantSubdomain: string | undefined;

    // more than domain.cn, will always return the first
    if ((parts.length > 2 && isNaN(Number(parts[0]))) || parts[0] === 'local') {
      const firstPart = parts[0];
      const remainingDomain = parts.slice(1).join('.');
      this.logger.info()(`Found domain: ${firstPart} . ${remainingDomain}`);
      if (isNotAReservedSubdomain(firstPart)) {
        // We are on the root without any customer or admin subdomain eg. app.remberg.de or dev.remberg.dev
        applicationRootDomain = firstPart + '.' + remainingDomain;
        this.logger.debug()('Currently at environmentSubdomain: ' + firstPart);
      } else {
        applicationRootDomain = remainingDomain;
        tenantSubdomain = firstPart;
        this.logger.debug()('Currently at tenantSubdomain: ' + tenantSubdomain);
      }
    } else {
      applicationRootDomain = host;
      this.logger.info()('Currently at ApplicationRootDomain: ' + applicationRootDomain);
    }
    this.store.dispatch(
      GlobalActions.applicationDomainInitialized({
        applicationDomain: {
          applicationRootDomain,
          tenantSubdomain,
        },
      }),
    );
    await firstValueFrom(
      this.store.select(GlobalSelectors.selectApplicationDomainState).pipe(filter(Boolean)),
    );
  }

  private async waitUntilBreakpointsInitialized(): Promise<void> {
    await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsBreakpointsInitialized).pipe(filter(Boolean)),
    );
  }

  private async initializeLocalUIStateFromLocalStorage(): Promise<void> {
    const rawUIState = this.appState.getValue(LocalStorageKeys.UI_STATE);
    const parsedUIState: UIState =
      rawUIState && rawUIState !== 'undefined' ? JSON.parse(rawUIState) : {};
    // isPrimaryNavigationOpen is a required value. If its value is undefined, there is nothing to restore.
    // We do not dispatch an action but keep the already initialized default values in the store.
    if (parsedUIState.isPrimaryNavigationOpen !== undefined) {
      this.store.dispatch(GlobalActions.localUIStatePropertyUpdated({ uiState: parsedUIState }));
    }
  }

  private async setupIonic(deviceType: DeviceTypeState): Promise<void> {
    this.logger.info()(
      'Initializing Ionic with connection status: ' + this.connectivityService.getCurrentStatus(),
    );

    const isIonicAppBelowMinimumRequiredVersion = !!this.appState.getValue(
      LocalStorageKeys.IONIC_APP_VERSION_INVALID,
    );
    this.store.dispatch(
      GlobalActions.setIsIonicAppBelowMinimumRequiredVersion({
        isIonicAppBelowMinimumRequiredVersion,
      }),
    );

    /**
     * This function sets up ionic specific functionality on mobile devices.
     * It should only be called on Capacitor runtimes but NOT on Electron or web runtimes.
     */
    if (isPlatform('capacitor') || deviceType.simulatedIonicType) {
      // This function is only called if isPlatform('capacitor') evaluates to true
      // Android and ios only since StatusBar plugin is not available on web.
      if (isPlatform('android') || isPlatform('ios')) {
        if (isPlatform('android') && Capacitor.isPluginAvailable('StatusBar')) {
          // setOverlaysWebView are currently not supported on iOS devices
          StatusBar.setOverlaysWebView({
            overlay: false, // removed due to keyboard issues on Android
          });
        }
        // This ensures that we ignore the Android hardware (or OS-level) back button.
        App.addListener('backButton', () => {
          // disable hardware back button functionality
        });
      }
      SplashScreen.hide();

      // Setup offline file service
      this.logger.debug()('Setting up the directories for the offline file service.');
      await this.filesSystemService.setupDirectories();
    }

    if (deviceType.isIonic) {
      // initialize DB
      await this.sqlDBMockService.initialize();
      await this.dbService.initializeDatabaseAndRunMobileMigrations();

      // initialize offline services and their SQL tables
      await Promise.all(
        [
          this.assetsOfflineService,
          this.assetTypesOfflineService,
          this.contactOfflineService,
          this.emailStatusOfflineService,
          this.platformFilesOfflineService,
          this.formInstanceOfflineService,
          this.formTemplateOfflineService,
          this.formTemplateVersionOfflineService,
          this.organizationOfflineService,
          this.partsOfflineService,
          this.serviceCaseOfflineService,
          this.userGroupOfflineService,
          this.workOrderOfflineService,
          this.workOrderStatusOfflineService,
          this.workOrderTypeOfflineService,
          this.workOrder2OfflineService,
          this.workOrderStatus2OfflineService,
          this.workOrderType2OfflineService,
        ].map((offlineService) => offlineService.initialize()),
      );

      this.logger.debug()('SQL DB and Table initialization complete!');
    }

    if (this.connectivityService.offlineCapabilitiesEnabled()) {
      this.syncUIService.offlineOnlyInit();
    }

    this.logger.info()('Ionic Initialization complete!');
  }

  private async showErrorPopup(error: string): Promise<void> {
    const descriptionTexts = [
      $localize`:@@weAreSorryForTheInconvenience:
                We are sorry for the inconvenience!`,
      $localize`:@@pleaseTryToRefreshTheApplication:Please try to refresh the application.`,
      $localize`:@@ifTheIssuePersistsCommaPleaseNoteDownTheErrorBelowAndContactTheRembergSupport:
                If the issue persists, please note down the error below and contact the remberg support.`,
      error,
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogBackdropCloseDisabled: true,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          headerTitle: '',
          headerCloseActionShow: false,
          styleNoMargin: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'report',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@unexpectedError:Unexpected Error`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons: [] },
        ],
      },
    };
    await this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
}
