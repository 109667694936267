import { ProductCompatibility } from '@remberg/assets/common/main';
import { compareIDs } from '@remberg/global/ui';

export function hasOrganizationMismatch(
  organizationId: string | undefined,
  tenantOrganizationId: string | undefined,
  asset: ProductCompatibility | undefined,
): boolean {
  const isTenantOwnerOrg =
    !!organizationId || !!tenantOrganizationId
      ? undefined
      : organizationId === tenantOrganizationId;
  return (
    !!organizationId &&
    !!asset &&
    isTenantOwnerOrg === false &&
    !compareIDs(organizationId, asset?.customerOrganization)
  );
}
