import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { TransientFile } from '../definitions/transient-file';
import { LogService } from './log.service';

/**
 * This service is used to store files temporarily in memory to avoid passing them through
 * the store (to enable runtime serializability checks).
 */
@Injectable({ providedIn: 'root' })
export class TransientFilesService {
  private map = new Map<string, File>();

  constructor(private readonly logger: LogService) {}

  public set(file: undefined): undefined;
  public set(file: File): TransientFile;
  public set(file: File | undefined): TransientFile | undefined;
  public set(file: File | undefined): TransientFile | undefined {
    if (!file) {
      this.logger.debug()('TransientFilesService.set (passthrough)', 'items count:', this.map.size);
      return undefined;
    }

    const transientFile = toTransientFile(file);
    this.logger.debug()('TransientFilesService.set', transientFile);
    this.map.set(transientFile.transientId, file);
    this.logger.debug()('TransientFilesService.set', 'items count:', this.map.size);
    return transientFile;
  }

  public pluck(transientFile: TransientFile): File {
    this.logger.debug()('TransientFilesService.pluck', transientFile);
    const file = this.map.get(transientFile.transientId);
    if (!file) {
      throw new Error(`TransientFile missed! ${JSON.stringify(transientFile)}`);
    }
    this.map.delete(transientFile.transientId);
    this.logger.debug()('TransientFilesService.pluck', 'items count:', this.map.size);
    return file;
  }

  public flush(): void {
    this.logger.debug()('TransientFilesService.flush');
    this.map.clear();
    this.logger.debug()('TransientFilesService.flush', 'items count:', this.map.size);
  }
}

function toTransientFile(file: File): TransientFile {
  return {
    size: file.size,
    name: file.name,
    type: file.type,
    transientId: uuid(),
    webkitRelativePath: file.webkitRelativePath,
  };
}
