import { Assert, TwoWayCompareStringType } from '@remberg/global/common/core';
import { SettingsPermissions } from './permissions.interface';

export enum SettingsPermissionsEnum {
  SETTINGS_ENABLED = 'settings_enabled',
  SETTINGS_MANAGE_GENERAL_INFORMATION = 'settings_manageGeneralInformation',
  SETTINGS_MANAGE_APPEARANCE = 'settings_manageAppearance',
  SETTINGS_MANAGE_USERS = 'settings_manageUsers',
  SETTINGS_MANAGE_USER_ACCESS_REQUESTS = 'settings_manageUserAccessRequests',
  SETTINGS_MANAGE_USER_ROLES = 'settings_manageUserRoles',
  SETTINGS_MANAGE_USER_GROUPS = 'settings_manageUserGroups',
  SETTINGS_MANAGE_LOCALIZATION = 'settings_manageLocalization',
  SETTINGS_MANAGE_CASES = 'settings_manageCases',
  SETTINGS_MANAGE_APPOINTMENTS = 'settings_manageAppointments',
  SETTINGS_MANAGE_ORGANIZATIONS = 'settings_manageOrganizations',
  SETTINGS_MANAGE_WORK_ORDERS = 'settings_manageWorkOrders',
  SETTINGS_MANAGE_DATA_EXPORT = 'settings_manageDataExport',
  SETTINGS_MANAGE_PASSWORD = 'settings_managePassword',
  SETTINGS_MANAGE_API_KEYS = 'settings_manageApiKeys',
  SETTINGS_MANAGE_ASSETS = 'settings_manageAssets',
}

export type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<`settings_${keyof SettingsPermissions}`, `${SettingsPermissionsEnum}`>
>;
