<div class="filter-entry-wrapper" *ngIf="filter">
  <span *ngIf="first" i18n="@@where" class="text-particle">Where</span>
  <span *ngIf="!first" i18n="@@And" class="text-particle">And</span>

  <!-- Filter Identifier Select -->
  <mat-form-field
    appearance="outline"
    class="my-mat-form-field my-mat-form-field-outline filter-type-item"
    attr.data-test-id="identifier-select-{{ filter.identifier }}"
    data-filter-type
  >
    <mat-label i18n="@@filter">Filter</mat-label>
    <mat-select
      #keySelect
      [value]="filter.identifier"
      disableOptionCentering
      (selectionChange)="filterIdentifierChange($event.value)"
      attr.data-test-id="type-select-{{ filter.identifier }}"
    >
      <!-- Search Option -->
      <mat-option data-test-id="search-field">
        <ngx-mat-select-search
          [placeholderLabel]="translations.search"
          [noEntriesFoundLabel]="translations.noEntriesFound"
          [formControl]="filterCtrl"
          [disableScrollToActiveOnOptionsChanged]="true"
          [clearSearchInput]="false"
        >
        </ngx-mat-select-search>
      </mat-option>

      <!-- Options -->
      <mat-option
        [value]="availableFilterFieldOption.identifier"
        *ngFor="let availableFilterFieldOption of filteredAvailableFilterFieldOptions ?? []"
        attr.data-test-id="option-key-{{ availableFilterFieldOption.identifier }}"
      >
        {{ filterFieldOptions?.[availableFilterFieldOption.identifier]?.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <span class="text-between" i18n="@@is" class="text-particle">is</span>

  <!-- Filter Value Select -->
  <ng-container [ngSwitch]="filterFieldOptions?.[filter.identifier]?.type">
    <app-organization-basic-select
      *ngSwitchCase="FilterTypeEnum.ORGANIZATION"
      appearance="outline"
      [limitWidth]="true"
      [organization]="filter.value"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (changedOrganization)="filterValueChange($event._id)"
      [includeOwnOrganization]="true"
      [allowNoneSelection]="filterFieldOptions?.[filter.identifier]?.anyAndNoneOptions ?? false"
      [dataTestId]="filter.identifier"
    />

    <app-tenant-select
      *ngSwitchCase="FilterTypeEnum.TENANT"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      (onTenantChange)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-compatibility-asset-type-select
      *ngSwitchCase="FilterTypeEnum.ASSET_TYPE"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      (onAssetTypeChange)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
      [allowNoneSelection]="filterFieldOptions?.[filter.identifier]?.anyAndNoneOptions"
    />

    <app-compatibility-asset-select
      *ngSwitchCase="FilterTypeEnum.ASSET"
      appearance="outline"
      [initialAssetId]="filter.value"
      [patchAsset]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [placeholderLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (onAssetChange)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
      [allowNoneSelection]="filterFieldOptions?.[filter.identifier]?.anyAndNoneOptions ?? false"
    />

    <app-contact-basic-select
      *ngSwitchCase="FilterTypeEnum.CONTACT"
      appearance="outline"
      [contact]="
        filter | getFilterValue: filterFieldOptions?.[filter.identifier]?.anyAndNoneOptions ?? false
      "
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (changedContact)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
      [organizationType]="filterFieldOptions?.[filter.identifier]?.organizationType"
      [allowAnySelection]="filterFieldOptions?.[filter.identifier]?.anyAndNoneOptions ?? false"
      [allowNoneSelection]="filterFieldOptions?.[filter.identifier]?.anyAndNoneOptions ?? false"
    />

    <app-support-email-select
      *ngSwitchCase="FilterTypeEnum.SUPPORT_EMAIL"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (onSupportEmailChange)="filterValueChange($event)"
      [dataTestId]="filter.identifier"
    />

    <app-case-select
      *ngSwitchCase="FilterTypeEnum.CASE"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (caseChanged)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-case-category-select
      *ngSwitchCase="FilterTypeEnum.CASE_CATEGORY"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (onCaseCategoryChange)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-maintenance-plan-select
      *ngSwitchCase="FilterTypeEnum.MAINTENANCE_PLAN"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label"
      (onMaintenancePlanChange)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-work-order-status-select
      *ngSwitchCase="FilterTypeEnum.WORK_ORDER_STATUS"
      [initialValueId]="filter.value"
      [label]="filterFieldOptions?.[filter.identifier]?.label"
      (changedStatus)="filterValueChange($event._id)"
    />

    <app-work-order-type-select
      *ngSwitchCase="FilterTypeEnum.WORK_ORDER_TYPE"
      [initialValueId]="filter.value"
      [label]="filterFieldOptions?.[filter.identifier]?.label"
      (changedType)="filterValueChange($event._id)"
    />

    <app-user-group-select
      *ngSwitchCase="FilterTypeEnum.USER_GROUP"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label"
      (userGroupChanged)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <mat-form-field
      *ngSwitchCase="FilterTypeEnum.STATIC_SELECT"
      appearance="outline"
      class="my-mat-form-field-outline"
      attr.data-test-id="{{ filter.identifier }}"
    >
      <mat-label>{{ filterFieldOptions?.[filter.identifier]?.label }}</mat-label>
      <mat-select
        [value]="filter.value"
        attr.data-test-id="value-select-{{ filter.identifier }}"
        disableOptionCentering
        [panelClass]="'dropdown-select-panel-set-width'"
        (selectionChange)="filterValueChange($event.value)"
      >
        <mat-option
          *ngFor="let filterValueOption of filterFieldOptions?.[filter.identifier]?.options"
          [value]="filterValueOption.identifier"
          attr.data-test-id="option-key-{{ filterValueOption.identifier }}"
        >
          {{ filterValueOption.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      *ngSwitchCase="FilterTypeEnum.ENUM"
      appearance="outline"
      class="my-mat-form-field-outline"
      attr.data-test-id="{{ filter.identifier }}"
    >
      <mat-label>{{ filterFieldOptions?.[filter.identifier]?.label }}</mat-label>
      <mat-select
        [value]="filter.value"
        attr.data-test-id="value-select-{{ filter.identifier }}"
        disableOptionCentering
        [panelClass]="'dropdown-select-panel-set-width'"
        (selectionChange)="filterValueChange($event.value)"
      >
        <mat-option
          *ngFor="let filterValueOption of filterFieldOptions?.[filter.identifier]?.options"
          [value]="filterValueOption.identifier"
          attr.data-test-id="option-key-{{ filterValueOption.identifier }}"
        >
          {{ filterValueOption.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      *ngSwitchCase="FilterTypeEnum.BOOLEAN"
      appearance="outline"
      class="my-mat-form-field-outline"
      attr.data-test-id="{{ filter.identifier }}"
    >
      <mat-label>{{ filterFieldOptions?.[filter.identifier]?.label }}</mat-label>
      <mat-select
        [value]="filter.value"
        attr.data-test-id="value-select-{{ filter.identifier }}"
        disableOptionCentering
        [panelClass]="'dropdown-select-panel-set-width'"
        (selectionChange)="filterValueChange($event.value)"
      >
        <mat-option
          *ngFor="let filterValueOption of booleanFilterValueOptions"
          [value]="filterValueOption.value"
          attr.data-test-id="option-key-{{ filterValueOption.value }}"
        >
          {{ filterValueOption.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-date-picker
      *ngSwitchCase="FilterTypeEnum.DATE"
      [name]="filter.identifier"
      [label]="filterFieldOptions?.[filter.identifier]?.label"
      [ngModel]="filter.value"
      [isClearButtonHidden]="true"
      (ngModelChange)="filterValueChange($event)"
      data-test-id="{{ filter.identifier }}"
    />

    <app-text-input
      *ngSwitchCase="FilterTypeEnum.TEXT"
      [dataTestId]="filter.identifier"
      [label]="filterFieldOptions?.[filter.identifier]?.label"
      [value]="filter.value"
      (changedValue)="filterValueChange($event)"
    />
  </ng-container>

  <mat-icon
    aria-hidden="false"
    aria-label="Clear icon"
    class="remove-icon"
    (click)="remove.emit()"
    attr.data-test-id="remove-{{ filter.identifier }}"
  >
    clear
  </mat-icon>
</div>
