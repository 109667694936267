import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LocalStorageKeys,
  LogService,
  StorageService,
  filterEmptyProps,
} from '@remberg/global/ui';
import {
  IntercomHashes,
  RembergUser,
  RembergUserActivationBody,
  RembergUserCheckActivationResponse,
  RembergUserConfirmPhoneBody,
  RembergUserFindByEmailBody,
  RembergUserFindManyForSchedulingBody,
  RembergUserFindManyForTableAdminResponse,
  RembergUserFindManyForTableQuery,
  RembergUserFindManyForTableResponse,
  RembergUserFindOneQuery,
  RembergUserLoginBody,
  RembergUserLoginResponse,
  RembergUserPopulated,
  RembergUserRequestAccessBody,
  RembergUserSchedulingListItem,
  RembergUserUpdateSelfBody,
  RembergUserVerifyEmailBody,
  RembergUsersCheckTokenBody,
  RembergUsersSendPasswordResetBody,
  RembergUsersSetPasswordBody,
} from '@remberg/users/common/main';
import { Observable, from, map } from 'rxjs';

export type RembergUserLoginParams = Omit<RembergUserLoginBody, 'tz'>;

@Injectable({
  providedIn: 'root',
})
export class RembergUsersService {
  private readonly rembergUsersUrl = `${API_URL_PLACEHOLDER}/users/v1`;
  private readonly rembergUsersIamUrl = `${API_URL_PLACEHOLDER}/users/v1/iam`;

  constructor(
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly http: HttpClient,
    private readonly logger: LogService,
    private readonly storageService: StorageService,
  ) {}

  /**
   * @description
   * Only to be used during initialization. In order to fetch the current remberg user use the store.
   */
  public findOneForGlobalInitializationOrThrow(userId: string): Observable<RembergUser> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()(`Getting Local Remberg User in offline mode ${userId}`);

      return from(this.storageService.get(LocalStorageKeys.IONIC_CURRENT_REMBERG_USER)).pipe(
        map((rembergUserString: string | undefined) => {
          if (!rembergUserString) {
            throw new Error('Local Remberg User not found');
          }
          const rembergUser: RembergUser = JSON.parse(rembergUserString);
          if (rembergUser._id !== userId) {
            throw new Error(
              `Local Remberg User Id ${rembergUser._id} does not match requested id ${userId}`,
            );
          }
          return rembergUser;
        }),
      );
    }

    return this.findOne(userId).pipe(
      map((rembergUser) => {
        if (!rembergUser) {
          throw new Error('Remberg User not found');
        }
        return rembergUser;
      }),
    );
  }

  public findManyForTable(
    options: RembergUserFindManyForTableQuery,
  ): Observable<RembergUserFindManyForTableResponse> {
    const { filterObject, staticFilters, ...queryParams } = options;
    const httpParams = new HttpParams({
      fromObject: {
        ...filterEmptyProps(queryParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
      },
    });
    return this.http.get<RembergUserFindManyForTableResponse>(this.rembergUsersUrl, {
      params: httpParams,
    });
  }

  public findManyForTableAdmin(
    options: RembergUserFindManyForTableQuery,
  ): Observable<RembergUserFindManyForTableAdminResponse> {
    const { filterObject, staticFilters, ...queryParams } = options;
    const httpParams = new HttpParams({
      fromObject: {
        ...filterEmptyProps(queryParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
      },
    });

    return this.http.get<RembergUserFindManyForTableAdminResponse>(
      `${this.rembergUsersUrl}/admin`,
      {
        params: httpParams,
      },
    );
  }

  public findManyForOrganization(
    organizationId: string,
    options?: RembergUserFindManyForTableQuery,
  ): Observable<RembergUserFindManyForTableResponse> {
    const { filterObject, staticFilters, populate, ...queryParams } = options ?? {};
    const httpParams = new HttpParams({
      fromObject: {
        ...filterEmptyProps(queryParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
        ...(populate && { populate: JSON.stringify(populate) }),
      },
    });

    return this.http.get<RembergUserFindManyForTableResponse>(
      `${this.rembergUsersUrl}/admin/${organizationId}`,
      {
        params: httpParams,
      },
    );
  }

  public hasAssignedRembergUserByUserRoleId(userRoleId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.rembergUsersUrl}/userrole/${userRoleId}`);
  }

  public findOneByEmail(
    rembergUserFindByEmailBody: RembergUserFindByEmailBody,
  ): Observable<RembergUser> {
    return this.http.post<RembergUser>(`${this.rembergUsersUrl}/email`, rembergUserFindByEmailBody);
  }

  public findOne(id: string): Observable<RembergUser>;
  public findOne(id: string, query: RembergUserFindOneQuery): Observable<RembergUserPopulated>;
  public findOne(
    id: string,
    query?: RembergUserFindOneQuery,
  ): Observable<RembergUserPopulated | RembergUser> {
    let params = new HttpParams();
    const { populate } = query ?? {};
    if (populate?.length) {
      params = params.append('populate', JSON.stringify(populate));
    }
    return this.http.get<RembergUser>(`${this.rembergUsersUrl}/${id}`, { params });
  }

  public fetchIntercomHashes(): Observable<IntercomHashes> {
    return this.http.get<IntercomHashes>(`${this.rembergUsersUrl}/intercom`);
  }

  public findManyForScheduling(
    body: RembergUserFindManyForSchedulingBody,
  ): Observable<RembergUserSchedulingListItem[]> {
    return this.http.put<RembergUserSchedulingListItem[]>(
      `${this.rembergUsersUrl}/scheduling`,
      body,
    );
  }

  public updateSelf(rembergUserUpdateSelfBody: RembergUserUpdateSelfBody): Observable<RembergUser> {
    return this.http.patch<RembergUser>(`${this.rembergUsersUrl}/self`, rembergUserUpdateSelfBody);
  }

  public verifyEmail(body: RembergUserVerifyEmailBody): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/verification/confirm`, body);
  }

  public resendActivationMail(rembergUserId: string): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/${rembergUserId}/activation/email`, {});
  }

  public sendVerificationEmail(): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/verification/email`, {});
  }

  public sendPasswordResetEmail(body: RembergUsersSendPasswordResetBody): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/password/email`, body);
  }

  public setPassword(body: RembergUsersSetPasswordBody): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/password`, body);
  }

  public checkResetToken(body: RembergUsersCheckTokenBody): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/password/token`, body);
  }

  public checkActivationToken(
    body: RembergUsersCheckTokenBody,
  ): Observable<RembergUserCheckActivationResponse> {
    return this.http.post<RembergUserCheckActivationResponse>(
      `${this.rembergUsersIamUrl}/activation/token`,
      body,
    );
  }

  public checkVerificationToken(body: RembergUsersCheckTokenBody): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/verification/token`, body);
  }

  public activateUser(body: RembergUserActivationBody): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/activation`, body);
  }

  public revokeInvite(rembergUserId: string): Observable<void> {
    return this.http.post<void>(
      `${this.rembergUsersIamUrl}/${rembergUserId}/activation/revokeinvite`,
      {},
    );
  }

  public suspendUser(rembergUserId: string): Observable<void> {
    return this.http.post<void>(
      `${this.rembergUsersIamUrl}/${rembergUserId}/activation/suspend`,
      {},
    );
  }

  public sendPhoneVerification(): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/verifyphone`, {});
  }

  public confirmPhoneVerification(body: RembergUserConfirmPhoneBody): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/verifyphone/confirm`, body);
  }

  public assignUsersToGroup(groupId: string, userIds: string[]): Observable<void> {
    return this.http.patch<void>(`${this.rembergUsersUrl}/groups/${groupId}`, { userIds });
  }

  public logout(): Observable<void> {
    return this.http.delete<void>(`${this.rembergUsersIamUrl}/logout`);
  }

  public login(body: RembergUserLoginParams): Observable<RembergUserLoginResponse> {
    const request = this.http.post<RembergUserLoginResponse>(
      `${this.rembergUsersIamUrl}/login`,
      body,
    );

    return request;
  }

  public requestAccess(body: RembergUserRequestAccessBody): Observable<void> {
    return this.http.post<void>(`${this.rembergUsersIamUrl}/requestaccess`, body);
  }
}
