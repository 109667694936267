import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { LogService } from '@remberg/global/ui';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private logger: LogService,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body) {
              if (typeof event.body.success === 'string') {
                this.toastr.success(event.body.success, $localize`:@@success:Success`);
              } else if (typeof event.body.info === 'string') {
                if (!event.body.info.startsWith('The references for steps')) {
                  this.toastr.info(event.body.info, $localize`:@@info:Info`);
                }
              } else if (typeof event.body.warning === 'string') {
                this.toastr.warning(event.body.warning, $localize`:@@warning:Warning`);
              }
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === HttpStatusCode.NotModified) {
              return;
            }
            if (err.status === 502) {
              this.toastr.error(
                'Internal routing error. A server is not responding.',
                $localize`:@@error:Error`,
              );
            }
            if (err.status === 426) {
              this.logger.warn()('Frontend upgrade required...');
              this.toastr.warning(
                $localize`:@@pleaseReloadThePage:Please reload the page`,
                $localize`:@@updateAvailable:Update available`,
                { disableTimeOut: true },
              );
            }
            if (err.error.warning) {
              this.toastr.warning(err.error.warning, $localize`:@@warning:Warning`);
            }
          }
        },
      ),
    );
  }
}
