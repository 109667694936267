import { IngestionJobFailureEnum } from '@remberg/ai/common/main';

export enum AiKnowledgeBaseUpdateActionEnum {
  ADDED = 'added',
  FAILED = 'failed',
  REMOVED = 'removed',
}

export interface DriveFilesSetKnowledgebaseStatusBody {
  aiKnowledgeBaseAction: AiKnowledgeBaseUpdateActionEnum;
  ingestionJobFailure?: IngestionJobFailureEnum;
}
