import { Injectable } from '@angular/core';
import { StepsProvider } from '@remberg/forms/common/main';
import { RembergDate, RembergDateTime } from '@remberg/global/common/core';
import {
  WorkOrderCustomPropertyTypesEnum,
  WorkOrderCustomPropertyValue,
} from '@remberg/work-orders-legacy/common/main';
import { PrefillingStepFunctions } from './interfaces';

@Injectable()
export class CustomPropertyValuePrefillingStepsProvider
  implements StepsProvider<PrefillingStepFunctions>
{
  public getSteps(): Partial<PrefillingStepFunctions> {
    return {
      customPropertyValueToString: (cVal: unknown | undefined) =>
        isCustomProperty(cVal) && typeof cVal.value === 'string' ? cVal.value : undefined,
      customPropertyValueToNumber: (cVal: unknown | undefined) =>
        typeof (cVal as WorkOrderCustomPropertyValue).value === 'number'
          ? ((cVal as WorkOrderCustomPropertyValue).value as number)
          : undefined,
      customPropertyValueToNumberString: (cVal: unknown | undefined) =>
        typeof (cVal as WorkOrderCustomPropertyValue).value === 'number'
          ? (cVal as WorkOrderCustomPropertyValue).value + ''
          : undefined,
      customPropertyValueToBoolean: (cVal: unknown | undefined) =>
        typeof (cVal as WorkOrderCustomPropertyValue).value === 'boolean'
          ? ((cVal as WorkOrderCustomPropertyValue).value as boolean)
          : undefined,
      customPropertyValueToArrayOfStrings,
      customPropertyValueToAssetId,
      customPropertyValueToUserId,
      customPropertyValueToAccountId,
      customPropertyValueToDate,
      customPropertyValueToDateTime,
    };
  }
}

function isCustomProperty(cPropVal: unknown | undefined): cPropVal is WorkOrderCustomPropertyValue {
  return (
    !!cPropVal &&
    !!(cPropVal as WorkOrderCustomPropertyValue).value &&
    !!(cPropVal as WorkOrderCustomPropertyValue).property.fieldType
  );
}

function customPropertyValueToDateTime(cVal: unknown | undefined): RembergDateTime | undefined {
  return isCustomProperty(cVal) &&
    typeof cVal.value === 'string' &&
    cVal.property.fieldType === WorkOrderCustomPropertyTypesEnum.REMBERG_DATETIME
    ? cVal.value
    : undefined;
}

function customPropertyValueToDate(cVal: unknown | undefined): RembergDate | undefined {
  return isCustomProperty(cVal) &&
    typeof cVal.value === 'string' &&
    cVal.property.fieldType === WorkOrderCustomPropertyTypesEnum.REMBERG_DATE
    ? cVal.value
    : undefined;
}

function customPropertyValueToAccountId(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) &&
    typeof cVal.value === 'string' &&
    cVal.property.fieldType === WorkOrderCustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT
    ? cVal.value
    : undefined;
}

function customPropertyValueToUserId(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) &&
    typeof cVal.value === 'string' &&
    cVal.property.fieldType === WorkOrderCustomPropertyTypesEnum.REMBERG_CONTACT_SELECT
    ? cVal.value
    : undefined;
}

function customPropertyValueToArrayOfStrings(cVal: unknown | undefined): string[] | undefined {
  return isCustomProperty(cVal) &&
    Array.isArray(cVal.value) &&
    cVal.property.fieldType === WorkOrderCustomPropertyTypesEnum.SELECT
    ? cVal.value
    : undefined;
}

function customPropertyValueToAssetId(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) &&
    typeof cVal.value === 'string' &&
    cVal.property.fieldType === WorkOrderCustomPropertyTypesEnum.REMBERG_ASSET_SELECT
    ? cVal.value
    : undefined;
}
