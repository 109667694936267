import { isDefined } from './is-defined';

export function isEmpty<T>(value: T | undefined | null): boolean {
  return (
    !isDefined(value) ||
    isEmptyArray(value) ||
    value === '' ||
    (isEmptyObject(value) && !isDate(value))
  );
}

export function isNotEmpty<T>(value: T | undefined | null): boolean {
  return !isEmpty(value);
}

function isEmptyArray(value: unknown): boolean {
  return Array.isArray(value) && !value.length;
}
function isEmptyObject(obj: unknown): boolean {
  return typeof obj === 'object' && !Object.getOwnPropertyNames(obj).length;
}
function isDate(obj: unknown): obj is Date {
  return obj instanceof Date;
}
