import { Component } from '@angular/core';
import { MaterialModule } from '@remberg/global/ui';
import { MyRouterLinkDirective, SanitizeUrlPipe } from '@remberg/ui-core/shared-main';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  standalone: true,
  imports: [MaterialModule, MyRouterLinkDirective, SanitizeUrlPipe],
})
export class PageNotFoundComponent {}
