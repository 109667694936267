import { Asset } from '@remberg/assets/common/main';
import { ContactCompatibility, OrganizationCompatibility } from '@remberg/crm/common/main';
import { compareIDs, getStringID, isObjectID } from '@remberg/global/ui';
import {
  CaseCustomProperty,
  CaseCustomPropertyTypesEnum,
  CaseCustomPropertyValue,
} from '@remberg/tickets/common/main';
import {
  WorkOrderCustomProperty,
  WorkOrderCustomPropertyTypesEnum,
  WorkOrderCustomPropertyValue,
} from '@remberg/work-orders-legacy/common/main';
import { DatetimeService } from '../services/datetime.service';

type CustomPropertyTypes = WorkOrderCustomProperty | CaseCustomProperty;
type CustomPropertyFieldTypeEnum = WorkOrderCustomPropertyTypesEnum | CaseCustomPropertyTypesEnum;
type CustomPropertyFieldTypeValue = WorkOrderCustomPropertyValue | CaseCustomPropertyValue;

export function cleanCustomPropertyValue(value: any, fieldType: CustomPropertyFieldTypeEnum): any {
  switch (fieldType) {
    case CaseCustomPropertyTypesEnum.REMBERG_CONTACT_SELECT:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_CONTACT_SELECT: {
      if (isObjectID(value)) {
        // Value does not have contact in it
        // which means it should be the ID of the contact selected
        return value;
      }
      if (!value?.contact && value?._id) {
        // Value is stored directly
        return getStringID(value);
      }
      if (value?.contact === null) {
        // No value is selected for contact
        // ContactCompatibility Select component sent {contact: null, organization: null}
        return undefined;
      }
      // Value has contact and contact is not null
      // which means we have to clean the value and put id in it
      return value?.contact ? getStringID(value.contact) : undefined;
    }
    case CaseCustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT: {
      if (isObjectID(value)) {
        // Value does not have organization in it
        // which means it should be the ID of the organization selected
        return value;
      }
      if (!value?.organization && value?._id) {
        // Value is stored directly
        return getStringID(value);
      }
      if (value.organization === null) {
        // No value is selected for organization
        // OrganizationCompatibility Select component sent {organization: null }
        return undefined;
      }
      // Value has organization and organization is not null
      // which means we have to clean the value and put id in it
      return value.organization ? getStringID(value.organization) : undefined;
    }
    case CaseCustomPropertyTypesEnum.REMBERG_ASSET_SELECT:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_ASSET_SELECT: {
      if (isObjectID(value)) {
        // Value does not have organization in it
        // which means it should be the ID of the organization selected
        return value;
      }
      if (!value?.asset && value?._id) {
        // Value is stored directly
        return getStringID(value);
      }
      if (value.asset === null) {
        // No value is selected for contact
        // ContactCompatibility Select component sent {contact: null, organization: null}
        return undefined;
      }
      // Value has organization and organization is not null
      // which means we have to clean the value and put id in it
      return value.asset ? getStringID(value.asset) : undefined;
    }
    case CaseCustomPropertyTypesEnum.REMBERG_DATE:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_DATE:
    case CaseCustomPropertyTypesEnum.REMBERG_DATETIME:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_DATETIME: {
      // date and time are two different objects in dirty payload, add them into the same TZ time string
      if (value.date) {
        return value.date;
      }
      return null;
    }
    default: {
      return value;
    }
  }
}

export function propertyValueAsFormControl(
  value: any,
  fieldType: CustomPropertyFieldTypeEnum,
  organization: OrganizationCompatibility,
): any {
  switch (fieldType) {
    case CaseCustomPropertyTypesEnum.REMBERG_CONTACT_SELECT:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_CONTACT_SELECT: {
      if (!value?.contact) {
        return {
          contact: value,
          organization: organization,
        };
      } else {
        return value;
      }
    }
    case CaseCustomPropertyTypesEnum.REMBERG_ASSET_SELECT:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_ASSET_SELECT: {
      if (!value?.asset) {
        return {
          asset: value,
          customerFilter: null,
        };
      } else {
        return value;
      }
    }
    case CaseCustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT: {
      if (!value?.organization) {
        return {
          organization: value,
          assetFilter: null,
        };
      } else {
        return value;
      }
    }
    case CaseCustomPropertyTypesEnum.REMBERG_DATETIME:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_DATETIME: {
      if (!value?.date) {
        return {
          date: value,
        };
      } else {
        return value;
      }
    }
    case CaseCustomPropertyTypesEnum.REMBERG_DATE:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_DATE: {
      if (!value?.date) {
        return {
          date: value,
        };
      } else {
        return value;
      }
    }
    default: {
      return value;
    }
  }
}

export function sortCustomProperties<T extends CustomPropertyTypes>(customProperties: T[]): T[] {
  return customProperties.sort((a, b) => a.sortIndex - b.sortIndex);
}

export function getValueFromCustomProperty(
  fieldType: CustomPropertyFieldTypeEnum,
  value: any,
  datetimeService: DatetimeService,
  organizationList?: OrganizationCompatibility[],
  contactList?: ContactCompatibility[],
  assetList?: Asset[],
): string {
  if (value === 'none' && fieldType !== WorkOrderCustomPropertyTypesEnum.CHECKBOX) {
    return $localize`:@@none:None`;
  }
  switch (fieldType) {
    case CaseCustomPropertyTypesEnum.CHECKBOX:
    case WorkOrderCustomPropertyTypesEnum.CHECKBOX: {
      return value === true ? $localize`:@@checked:Checked` : $localize`:@@unchecked:Unchecked`;
    }
    case CaseCustomPropertyTypesEnum.REMBERG_CONTACT_SELECT:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_CONTACT_SELECT: {
      let contact: ContactCompatibility | undefined;
      if (!value?.contact) {
        contact = contactList?.find((contact) => compareIDs(contact, value));
      } else {
        contact = contactList?.find((contact) => compareIDs(contact, value?.contact));
      }
      if (contact) {
        return `${contact?.crmData.firstName} ${contact?.crmData.lastName}`;
      } else {
        return $localize`:@@unknownContact:Unknown ContactCompatibility`;
      }
    }
    case CaseCustomPropertyTypesEnum.REMBERG_ASSET_SELECT:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_ASSET_SELECT: {
      let asset: Asset | undefined;
      if (!value?.product) {
        asset = assetList?.find((asset) => compareIDs(asset, value));
      } else {
        asset = assetList?.find((asset) => compareIDs(asset, value?.product));
      }
      if (asset) {
        return `${asset.serialNumber} (${asset.assetTypeLabel})`;
      } else {
        return $localize`:@@unknownAsset:Unknown Asset`;
      }
    }
    case CaseCustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT: {
      let organization: OrganizationCompatibility | undefined;
      if (!value?.organization) {
        organization = organizationList?.find((organization) => compareIDs(organization, value));
      } else {
        organization = organizationList?.find((organization) =>
          compareIDs(organization, value?.organization),
        );
      }
      if (organization) {
        return organization.crmData.name;
      } else {
        return $localize`:@@unknownOrganization:Unknown Organization`;
      }
    }
    case CaseCustomPropertyTypesEnum.REMBERG_DATE:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_DATE: {
      return datetimeService.formatDate(value, 'P');
    }
    case CaseCustomPropertyTypesEnum.REMBERG_DATETIME:
    case WorkOrderCustomPropertyTypesEnum.REMBERG_DATETIME: {
      return datetimeService.formatDate(value, 'Pp');
    }
    default: {
      return value;
    }
  }
}
