/* eslint-disable max-len */
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { FeatureFlagEnum, compareIfVersionHigher } from '@remberg/global/common/core';
import {
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  DEFAULT_DIALOG_WIDTH,
  LogService,
  SYNC_CHECK_INTERVAL,
  SYNC_MAX_RETRIES_ATTEMPTS,
  SYNC_REMINDER_LIMIT,
  SyncStateEnum,
} from '@remberg/global/ui';
import { OfflinePrefetchService } from './offline/offline-prefetch.service';
import { OfflinePushService } from './offline/offline-push.service';

import { KeepAwake } from '@capacitor-community/keep-awake';
import { Store } from '@ngrx/store';
import { VERSION } from '@remberg/global/common/version';
import {
  ActionEnum,
  ButtonActions,
  ChangeTypeEnum,
  DynamicButtonConfig,
  DynamicProgressBarConfig,
  LocalStorageKeys,
  PrefetchDataTypesEnum,
  PrefetchStatus,
  PushStatus,
  SyncUIStatus,
} from '@remberg/global/ui';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subscription, firstValueFrom, from, merge, of } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { DialogOptions, DialogResultData } from '../dialogs/dialogs';
import { DynamicPopUpComponent } from '../dialogs/dynamic-pop-up/dynamic-pop-up.component';
import { ModalDialogWrapper } from '../dialogs/modalDialogWrapper';
import { isAccountFeatureFlagEnabled } from '../helpers/checkFeatureHelper';
import { GlobalActions, GlobalSelectors, RootGlobalState, RouterSelectors } from '../store';
import { VersionService } from './api/version.service';
import { AppStateService } from './app-state.service';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class SyncUiService implements OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public offlineModeEnabled: boolean = false;
  public internalModeOnline: boolean = true; // App is on online mode
  private externalConnection: boolean = true; // App has internet access

  private lastPrefetchTime?: Date;
  private noSyncInLongTime: boolean = false;
  private hasSyncAtLeastOnce: boolean = false;

  private prefetchToaster?: ActiveToast<any>;
  private prefetchStatus?: PrefetchStatus;
  private pushStatus?: PushStatus;

  private currentSyncUiStatus?: SyncUIStatus;

  private activePopup?: ModalDialogWrapper;
  private confirmPopup?: ModalDialogWrapper;

  private progressBarConfig?: DynamicProgressBarConfig;
  private progressBarConfigEventEmitter = new EventEmitter<DynamicProgressBarConfig>();

  constructor(
    @Inject(CONNECTIVITY_SERVICE)
    private readonly _connectivityService: ConnectivityServiceInterface,
    private readonly _offlinePrefetchService: OfflinePrefetchService,
    private readonly _offlinePushService: OfflinePushService,
    private readonly _dialog: DialogService,
    private readonly _logger: LogService,
    private readonly _toastr: ToastrService,
    private readonly appState: AppStateService,
    private readonly store: Store<RootGlobalState>,
    private readonly versionService: VersionService,
  ) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.prefetchToaster) {
      this._toastr.remove(this.prefetchToaster.toastId);
    }
  }

  public async reset(): Promise<void> {
    if (this.currentSyncUiStatus) {
      this.currentSyncUiStatus = undefined;

      await this._offlinePrefetchService.terminateSync();
      await this._offlinePushService.terminatePushChanges();
      await this.activePopup?.closeDialogModal();
    }
  }

  /** This function should be called if the offline mode is enabled. */
  public offlineOnlyInit(): void {
    this.offlineModeEnabled = true;
    setTimeout(() => this._checkReminders(), 5000);
    this._initProgressBarConfig();
    this._initSubscriptions();
  }

  public async toggleSync(): Promise<void> {
    // check if sync is already in progress
    if (!this.currentSyncUiStatus) {
      // check if the syncing is toggled on forms instance detail page
      const isFormsFeatureEnabled = isAccountFeatureFlagEnabled(
        FeatureFlagEnum.FORMS,
        this.appState,
      );
      const isFormInstanceDetailPage = await firstValueFrom(
        this.store.select(RouterSelectors.selectIsFormInstanceDetailPage),
      );
      if (isFormsFeatureEnabled && isFormInstanceDetailPage) {
        // show popup to wait for any changes to be saved
        const dialog = this._showWaitForSaveToCompletePopup();
        const aborted = await firstValueFrom(
          merge(
            from(dialog.waitForCloseData()).pipe(map((result) => !result.confirmed)),
            this.store.select(GlobalSelectors.selectGlobalLoadingIndicatorIsSpinning).pipe(
              debounceTime(2000), // Add delay to make sure any debounced input change is saved
              filter((isBusy) => !isBusy),
              tap(() => dialog.closeDialogModal()),
            ),
          ),
        );

        if (aborted) {
          return;
        }
      }

      // switch online status
      if (!this.internalModeOnline) {
        await this._goingOnline();
      } else {
        await this._goingOffline();
      }
    } else {
      // let the user abort the sync (needs to confirm popup)
      if ((await this._showAbortSyncPopup())?.confirmed) {
        await this._offlinePushService.terminatePushChanges();
        await this._offlinePrefetchService.terminateSync();
        this.currentSyncUiStatus = undefined;
      }
    }
  }

  //#region Private Functions
  private _initSubscriptions(): void {
    // Internal connection status
    this.subscriptions.add(
      this._connectivityService.connection$.subscribe((connected) => {
        this.internalModeOnline = connected;
        // TO REMIND - Do we want to show a popup/toastr telling the user the internal mode after login ?
      }),
    );
    // External connection status
    this.subscriptions.add(
      this._connectivityService.externalConnection$.subscribe(async (externalConnection) => {
        this.externalConnection = externalConnection;

        if (!this.currentSyncUiStatus) {
          return;
        }

        if (this.currentSyncUiStatus === SyncUIStatus.UPDATING_DATA && !this.externalConnection) {
          this._logger.debug()('Lost connection during sync');
          await this._offlinePrefetchService.terminateSync();
          if (this.activePopup) {
            this.activePopup.closeDialogModal();
          }
          this.activePopup = this._showConnectionLostDuringSyncPopup();
          await this.activePopup.waitForCloseData();
          this.currentSyncUiStatus = undefined;
          return;
        }

        if (
          this.currentSyncUiStatus === SyncUIStatus.GOING_OFFLINE_OR_SYNC &&
          !this.externalConnection
        ) {
          this._logger.debug()('Lost connection during selection of GoingOffline or Syncing');
          if (this.activePopup) {
            this.activePopup.closeDialogModal();
          }
          this.activePopup = this._showConnectionLostDuringOfflineOrSyncPopup();
          await this.activePopup.waitForCloseData();
          this.currentSyncUiStatus = undefined;
          return;
        }

        if (this.currentSyncUiStatus === SyncUIStatus.GOING_OFFLINE && !this.externalConnection) {
          this._logger.debug()('Lost connection during goingOffline');

          if (this.activePopup) {
            this.activePopup.closeDialogModal();
          }

          this.activePopup = this._showConnectionLostGoingOfflinePopup();
          const data = await this.activePopup.waitForCloseData();
          const connectionRes = data.data;

          if (connectionRes?.action === ButtonActions.PROCEED_ANYWAYS) {
            await this._connectivityService.goOffline();
            // Refresh UI view: during the going-offline process the connection was lost but user wants to proceed anyway
            this._connectivityService.refreshView();
          }

          this.currentSyncUiStatus = undefined;
          return;
        }

        if (this.currentSyncUiStatus === SyncUIStatus.GOING_ONLINE && !this.externalConnection) {
          this._logger.debug()('Lost connection during goingOnline');
          await this._offlinePushService.terminatePushChanges();

          if (this.activePopup) {
            this.activePopup.closeDialogModal();
          }
          this.activePopup = this._showConnectionLostGoingOnlinePopup();
          await this.activePopup.waitForCloseData();
          this.currentSyncUiStatus = undefined;
          return;
        }
      }),
    );

    this.subscriptions.add(
      this._offlinePrefetchService.prefetchStatus.subscribe((prefetchStatus) => {
        this.prefetchStatus = prefetchStatus;
        if (this.prefetchStatus) {
          this._updateProgressBarConfigPrefetch(this.prefetchStatus);
        }
      }),
    );

    this.subscriptions.add(
      this._offlinePushService.pushStatus.subscribe((pushStatus) => {
        this.pushStatus = pushStatus;
        if (this.pushStatus) {
          this._updateProgressBarConfigPush(this.pushStatus);
        }
      }),
    );
  }

  /** Helper that implements the flow for going online */
  private async _goingOnline(): Promise<void> {
    this._logger.debug()('Going Online...');
    try {
      this.currentSyncUiStatus = SyncUIStatus.GOING_ONLINE;

      // Step 1 - Check for internet connection
      if (!this.externalConnection) {
        // Step 1.5 - Show warning popup
        this.activePopup = this._showConnectionNeededPopup();
        await this.activePopup.waitForCloseData();
        return;
      }

      // Step 2 - when connection is available - test if the app version is below the minimum allowed version of the backend
      const { minAllowedIonicAppVersion } = await firstValueFrom(this.versionService.getVersion());
      const isVersionInvalid = compareIfVersionHigher(minAllowedIonicAppVersion, VERSION);

      if (isVersionInvalid) {
        this.store.dispatch(
          GlobalActions.ionicAppVersionChecked({
            belowRequiredVersion: true,
            redirectImmediately: true,
          }),
        );
        return;
      }

      // Step 3 - Show popup
      this.activePopup = this._showSwitchModeToOnlinePopup();
      const data = await this.activePopup.waitForCloseData();
      const resSwitch = data.data;

      // Step 4 - Check if popup was not closed / dismissed
      if (!resSwitch || !resSwitch.confirmation) {
        return;
      }

      // Step 5 - Set global syncState = PushingDataToServer
      this.store.dispatch(
        GlobalActions.setSyncState({ syncState: SyncStateEnum.PushingDataToServer }),
      );

      this._initProgressBarConfig();
      // Step 6 - Start the upload of the data to finish
      await this._connectivityService.goOnline();
      try {
        const success = await this._uploadData();
        if (success) {
          if (this.confirmPopup) {
            this._logger.debug()('closing dialog');
            this.confirmPopup.closeDialogModal();
          }
        } else {
          await this._connectivityService.goOffline();
          if (this.externalConnection) {
            this.activePopup?.closeDialogModal({ error: true });
          }
        }
      } catch (error) {
        this._logger.error()(error);
        await this._connectivityService.goOffline();
        if (this.externalConnection) {
          this.activePopup?.closeDialogModal({ error: true });
        }
      }

      // Steps 7 & 8
      if (!(await this._uploading())) {
        // Sync was canceled stop running the function
        return;
      }

      // update global syncState = PullingDataFromServer
      this.store.dispatch(
        GlobalActions.setSyncState({ syncState: SyncStateEnum.PullingDataFromServer }),
      );

      // Step 9 - Start the download of the data to finish
      this._downloadData()
        .then((success) => {
          if (success) {
            if (this.confirmPopup) {
              this._logger.debug()('closing dialog');
              this.confirmPopup.closeDialogModal();
            }
          } else {
            this.activePopup?.closeDialogModal({ error: true });
          }
        })
        .catch((error) => {
          this._logger.error()(error);
          this.activePopup?.closeDialogModal({ error: true });
        });

      // Steps 10 & 11
      if (!(await this._downloading())) {
        // Sync was canceled stop running the function
        return;
      }

      // Step 12 - Show success updating
      this.activePopup = this._showUploadSuccessPopup();
      await this.activePopup.waitForCloseData();

      // Refresh UI after successful switching to the online mode
      this._connectivityService.refreshView();
    } catch (error) {
      this._logger.error()('Error going online', error);
      await this._offlinePushService.terminatePushChanges();
    } finally {
      this.currentSyncUiStatus = undefined;

      // Step 13 - clear global syncState value
      this.store.dispatch(GlobalActions.clearSyncState());
    }
  }

  /** Helper that implements the flow for going offline */
  private async _goingOffline(): Promise<void> {
    this._logger.debug()('Going Offline...');
    try {
      this.currentSyncUiStatus = SyncUIStatus.GOING_OFFLINE_OR_SYNC;

      // Step 1 - Check for internet connection
      if (!this.externalConnection) {
        // Step 1.5 - Show warning popup
        this.activePopup = this._showDataOutdatedPopup();
        const data = await this.activePopup.waitForCloseData();
        const resDataOutdated = data.data;

        //  Step 1.6 - If ButtonActions.RETRY - call the same function to check connection
        if (resDataOutdated?.action === ButtonActions.RETRY) {
          this.activePopup.closeDialogModal();
          return await this._goingOffline();
        }

        // Step 1.7 - If ButtonActions.PROCEED_ANYWAYS - set mode to offline directly
        if (resDataOutdated?.action === ButtonActions.PROCEED_ANYWAYS) {
          await this._connectivityService.goOffline();
          // Refresh UI view: no connection during the switching to the offline mode, but user wants to proceed anyway
          this._connectivityService.refreshView();
        }
        return;
      }

      // Step 2 - when connection is available - test if the app version is below the minimum allowed version of the backend
      const { minAllowedIonicAppVersion } = await firstValueFrom(this.versionService.getVersion());
      const isVersionInvalid = compareIfVersionHigher(minAllowedIonicAppVersion, VERSION);

      if (isVersionInvalid) {
        this.store.dispatch(
          GlobalActions.ionicAppVersionChecked({
            belowRequiredVersion: true,
            redirectImmediately: true,
          }),
        );
        return;
      }

      // Step 3 - Show popup
      this.activePopup = this._showSwitchModeToOfflinePopup();
      const data = await this.activePopup.waitForCloseData();
      const resSwitch = data.data;

      // Step 4 - Check if popup was not closed / dismissed
      if (!resSwitch || !resSwitch.confirmation) {
        return;
      }

      if (resSwitch.action === ButtonActions.GO_OFFLINE) {
        this.currentSyncUiStatus = SyncUIStatus.GOING_OFFLINE;
      } else if (resSwitch.action === ButtonActions.UPDATE_DATA) {
        this.currentSyncUiStatus = SyncUIStatus.UPDATING_DATA;
      }

      // Step 5 - Set global syncState = PullingDataFromServer
      this.store.dispatch(
        GlobalActions.setSyncState({ syncState: SyncStateEnum.PullingDataFromServer }),
      );

      this._initProgressBarConfig();

      // Step 6 - Start the download of the data to finish
      this._downloadData()
        .then((success) => {
          if (success) {
            if (this.confirmPopup) {
              this._logger.debug()('closing dialog');
              this.confirmPopup.closeDialogModal();
            }
          }
        })
        .catch((error) => {
          this._logger.error()(error);
        });

      // Steps 7 & 8
      if (!(await this._downloading())) {
        // Sync was canceled stop running the function
        return;
      }

      // Step 9 - Set Offline mode was selected
      if (this.currentSyncUiStatus === SyncUIStatus.GOING_OFFLINE) {
        await this._connectivityService.goOffline();
      }

      // Step 10 - Show success updating depending on going online or sync
      if (this.currentSyncUiStatus === SyncUIStatus.GOING_OFFLINE) {
        this.activePopup = this._showDownloadSuccessForGoingOfflinePopup();
        await this.activePopup.waitForCloseData();
      } else if (this.currentSyncUiStatus === SyncUIStatus.UPDATING_DATA) {
        this.activePopup = this._showDownloadSuccessForUpdatingPopup();
        const data = await this.activePopup.waitForCloseData();
        const resShow = data.data;

        if (resShow && resShow?.action === ButtonActions.GO_OFFLINE) {
          // Step 11 - Allow user to after only updating go offline with a button
          await this._connectivityService.goOffline();
          this.activePopup = this._showDownloadSuccessForGoingOfflinePopup();
          await this.activePopup.waitForCloseData();
        }
      }
      // Refresh UI after successful switching to the offline mode
      this._connectivityService.refreshView();
    } catch (error) {
      this._logger.error()('Error going offline', error);
      await this._offlinePrefetchService.terminateSync();
    } finally {
      this.currentSyncUiStatus = undefined;
      // Step 12 - clear global syncState value
      this.store.dispatch(GlobalActions.clearSyncState());
    }
  }

  public async goOfflineWithoutChecks(): Promise<void> {
    return this._goingOffline();
  }

  //#region Popups
  private _showConnectionLostDuringSyncPopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@yourInternetConnectionWasInterruptedDuringTheSynchronizationDotPleaseTryAgaunWhenYouHaveAMoreStableInternetConnectionDot:Your internet connection was interrupted during the synchronization. Please try again when you have a more stable internet connection.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@cancel:Cancel`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@internetConnectionLostExclamationMark:Internet connection lost !`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private _showConnectionLostDuringOfflineOrSyncPopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@yourInternetConnectionWasInterruptedDotPleaseTryAgaunWhenYouHaveAMoreStableInternetConnectionDot:Your internet connection was interrupted. Please try again when you have a more stable internet connection.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@cancel:Cancel`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@internetConnectionLostExclamationMark:Internet connection lost !`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private _showConnectionLostGoingOnlinePopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@yourInternetConnectionWasInterruptedDuringTheUploadingDotPleaseTryAgaunWhenYouHaveAMoreStableInternetConnectionDot:Your internet connection was interrupted during the uploading. Please try again when you have a more stable internet connection.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@cancel:Cancel`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@internetConnectionLostExclamationMark:Internet connection lost !`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private _showConnectionLostGoingOfflinePopup(): ModalDialogWrapper {
    const now = new Date().getTime();
    const hoursWithoutSync = this.lastPrefetchTime
      ? Math.floor((now - this.lastPrefetchTime.getTime()) / (1000 * 60 * 60))
      : '';
    const descriptionTexts = [
      $localize`:@@yourInternetConnectionWasInterruptedDuringTheSynchronizationDotProceedingAnywaysCanLeadToDataLossDot:Your internet connection was interrupted during the synchronization. Proceeding anyways can lead to data loss.`,
      $localize`:@@weDoNotRecommendGoingOfflineWithoutUpdatingYourLocalDataIfYourLastSyncWasMoreThanXHoursAgoDot:We do not recommend going Offline without updating your local data if your last sync was more than ${
        SYNC_REMINDER_LIMIT / 1000 / 60 / 60
      } hours ago.`,
      $localize`:@@yourLastSyncWasXHoursAgo:Your last sync was ${hoursWithoutSync} hours ago.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@cancel:Cancel`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
      {
        text: $localize`:@@proceedAnyways:Proceed Anyways`,
        category: 'success',
        color: 'primary',
        action: ButtonActions.PROCEED_ANYWAYS,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@internetConnectionLostExclamationMark:Internet connection lost !`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private _showConnectionNeededPopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@switchingToOnlineModeRequiresAnActiveInternetConnectionDotOtherwiseWeCannotquaranteeThatYourSystemIsUpToDateDotPleaseTryAgaiLaterDot:Switching to Online Mode requires an active Internet Connection. Otherwise we cannot guarantee that your app is up to date. Please try again later.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@cancel:Cancel`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@cannotSwitchToOnlineModeExclamationMark:Cannot switch to Online Mode !`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
  private _showDataOutdatedPopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@switchingToOfflineModeRequiresAnActiveInternetConnectionDotProceedingAnywaysCanLeadToDataLossDotPleaseCheckYourInternetConnectionAndTryAgainLaterDot:Switching to Offline Mode requires an active Internet Connection. Proceeding anyways can lead to data loss. Please check your Internet connection and try again later.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@proceedAnyways:Proceed Anyways`,
        category: 'success',
        color: 'primary',
        action: ButtonActions.PROCEED_ANYWAYS,
      },
      {
        text: $localize`:@@retry:Retry`,
        category: 'none',
        action: ButtonActions.RETRY,
      },
      {
        text: $localize`:@@cancel:Cancel`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'report_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@cannotSwitchToOfflineModeExclamationMark:Cannot switch to Offline Mode !`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttonsDirection: 'vertical' },
          { buttons },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private _showDownloadProgressPopup(): ModalDialogWrapper {
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@cancel:Cancel`,
        category: 'danger',
        action: ButtonActions.ABORT,
        dataTestId: 'offline-data-update-cancel-button',
      },
      {
        text: $localize`:@@ok:Ok`,
        category: 'success',
        action: ButtonActions.CONFIRM,
        color: 'primary',
        dataTestId: 'offline-data-update-complete-button',
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@updatingLocalData:Updating local data`,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { hasAnimation: true },
          { buttonsDirection: 'vertical' },
          { buttons },
          { progressBarConfig: this.progressBarConfig },
          { progressBarConfig$: this.progressBarConfigEventEmitter.asObservable() },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
  private _showUploadProgressPopup(): ModalDialogWrapper {
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@cancel:Cancel`,
        category: 'danger',
        action: ButtonActions.ABORT,
        dataTestId: 'offline-data-upload-cancel-button',
      },
      {
        text: $localize`:@@ok:Ok`,
        category: 'success',
        action: ButtonActions.CONFIRM,
        color: 'primary',
        dataTestId: 'offline-data-upload-complete-confirm-button',
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@switchingToOnlineMode:Switching to Online Mode`,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttonsDirection: 'vertical' },
          { buttons },
          { progressBarConfig: this.progressBarConfig },
          {
            progressBarConfig$: this.progressBarConfigEventEmitter.asObservable(),
          },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private _showWaitForSaveToCompletePopup(): ModalDialogWrapper {
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@cancel:Cancel`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'warning_amber',
              color: 'primary',
            },
          },
          {
            description: {
              text: [
                $localize`:@@inOrderToAvoidDataLossPleaseWaitForAnyBackgroundChangesToBeSaved:In order to avoid data loss, please wait for any background changes to be saved.`,
              ],
              position: 'center',
            },
          },
          { buttonsDirection: 'vertical' },
          { buttons },
          { showAutoSavingState: true },
          { savingInProgress$: of(true) },
        ],
      },
    };

    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private _showSwitchModeToOfflinePopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@offlineModeEnablesYouToWorkFromPlacesWithoutAnActiveInternetConnectionDotAllChangesAndMessagesWillBeUpdatedAfterSwitchingBackToOnlineModeDot:Offline Mode enables you to work from places without an active internet connection. All changes and messages will be updated after switching back to Online Mode.`,
      $localize`:@@clickQuotationUpdateDataQuotationToUpdateYourLocalSystemWithoutGoingOfflineDot:Click “Update Data” to update your local app without going Offline.`,
      $localize`:@@notePleaseDoNotForgetToDownloadDocuments:Note\: Please do not forget to download documents from your work orders before switching to Offline mode.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@updateData:Update Data`,
        category: 'success',
        action: ButtonActions.UPDATE_DATA,
        dataTestId: 'only-update-data-and-stay-online-button',
      },
      {
        text: $localize`:@@goOffline:Go Offline`,
        category: 'success',
        color: 'primary',
        action: ButtonActions.GO_OFFLINE,
        dataTestId: 'go-offline-button',
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@doYouWantToSwitchToOfflineMode:Do you want to switch to Offline Mode?`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'left',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttonsDirection: 'vertical' },
          { buttons },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
  private _showSwitchModeToOnlinePopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@onlineModeShouldOnlyBeUsedInPlacesWithAStableInternetConnectionDotOtherwiseThereMightBeARiskOfDataLossDotPleaseMakeSureThatYouAreConnectedToTheInternetDot:Online Mode should only be used in places with a stable Internet Connection. Otherwise there might be a risk of data loss. Please make sure that you are connected to the Internet.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@no:No`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
      {
        text: $localize`:@@yes:Yes`,
        category: 'success',
        action: ButtonActions.CONFIRM,
        color: 'primary',
        dataTestId: 'confirm-switch-to-online-mode-button',
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@doYouWantToSwitchToOnlineMode:Do you want to switch to Online Mode?`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'justify',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttonsDirection: 'vertical' },
          { buttons },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
  private _showDownloadConfirmationPopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@theSynchronizationProcessWillBeCancelledClickNo:The synchronization process will be cancelled. Click "No" to continue the synchronization process.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@yes:Yes`,
        category: 'success',
        color: 'warn',
        action: ButtonActions.CONFIRM,
      },
      {
        text: $localize`:@@no:No`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'report_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@doYouReallyWantToCancel:Do you really want to cancel?`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttonsDirection: 'vertical' },
          { buttons },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
  private _showUploadConfirmationPopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@theSynchronizationProcessWillBeCancelledClickNo:The synchronization process will be cancelled. Click "No" to continue the synchronization process.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@yes:Yes`,
        category: 'success',
        color: 'warn',
        action: ButtonActions.CONFIRM,
      },
      {
        text: $localize`:@@no:No`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'report_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@doYouReallyWantToCancel:Do you really want to cancel?`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
  private _showDownloadSuccessForGoingOfflinePopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@yourAppIsNowWorkingInOfflineMode:Your app is now working in Offline Mode.`,
    ];
    const buttons = [
      {
        text: $localize`:@@ok:OK`,
        category: 'success',
        action: ButtonActions.FINISH,
        dataTestId: 'finish-successful-offline-switch',
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'verified',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@youAreNowInOfflineMode:You are now in Offline Mode`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttonsDirection: 'vertical' },
          { buttons },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
  private _showDownloadSuccessForUpdatingPopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@yourLocalDataIsUpToDateDot:Your local data is up to date.`,
    ];
    const buttons = [
      {
        text: $localize`:@@ok:OK`,
        category: 'success',
        action: ButtonActions.FINISH,
      },
      {
        text: $localize`:@@goOffline:Go Offline`,
        category: 'success',
        action: ButtonActions.GO_OFFLINE,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'verified',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@successfullyUpdated:Successfully updated`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttonsDirection: 'vertical' },
          { buttons },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
  private _showUploadSuccessPopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@yourAppIsUpToDateAndWorkingInOnlineMode:Your app is up to date and working in Online Mode.`,
    ];
    const buttons = [
      {
        text: $localize`:@@ok:OK`,
        category: 'success',
        action: ButtonActions.FINISH,
        dataTestId: 'finish-successfull-online-switch',
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'verified',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@youAreNowInOnlineMode:You are now in Online Mode`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttonsDirection: 'vertical' },
          { buttons },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
  private _showReminderSyncUpdatePopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@yourLastUpdateWasMoreThan24HoursAgoDotPleaseUpdateYourSystemNowDot:
          Your last update was more than 24 hours ago. Please update your app now.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@tryLater:Try Later`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
      {
        text: $localize`:@@update:Update`,
        category: 'success',
        color: 'primary',
        action: ButtonActions.UPDATE_DATA,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'notification_important',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@pleaseUpdateYourSystem:Please update your app`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
  private _showReminderSyncGoOnlinePopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@youHaveBeenOfflineForOverXHoursDotPleaseUpdateYourSystemNowDot:
          You have been Offline for over ${
            SYNC_REMINDER_LIMIT / 1000 / 60 / 60
          } hours. Please update your app now.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@tryLater:Try Later`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
      {
        text: $localize`:@@goOnline:Go Online`,
        category: 'success',
        color: 'primary',
        action: ButtonActions.UPDATE_DATA,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'notification_important',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@pleaseUpdateYourSystem:Please update your app`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private _showReminderSyncGoOnlineWithoutExternalConnection(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@youHaveBeenOfflineForOverXHoursDotPleaseUpdateYourSystemAsSoonAsYouHaveAnInternetConnectionDot:
          You have been Offline for over ${SYNC_REMINDER_LIMIT / 1000 / 60 / 60} hours
          . Please update your app as soon as you have an Internet connection.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@close:close`,
        category: 'danger',
        color: 'primary',
        action: ButtonActions.ABORT,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'notification_important',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@pleaseUpdateYourSystem:Please update your app `,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private _showErrorDuringSync(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@thereHasBeenAnErrorDuringTheSynchronizationDot:There has been an error during the synchronization.`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@cancel:Cancel`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
      {
        text: $localize`:@@retry:Retry`,
        category: 'success',
        color: 'primary',
        action: ButtonActions.RETRY,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'report_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@error:Error`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private async _showAbortSyncPopup(): Promise<DialogResultData> {
    const descriptionTexts = [
      $localize`:@@doYouWantToAbortTheCurrentSynchronization:Do you want to abort the current synchronization?`,
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleNoMargin: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            description: {
              text: descriptionTexts,
              position: 'left',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
        ],
      },
    };
    const popup = this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
    return await popup.waitForCloseData();
  }

  //#endregion

  private async _downloadData(tryNb: number = 1): Promise<boolean> {
    if (!this.currentSyncUiStatus) {
      this._logger.debug()('Downloading data was aborted');
      return false;
    }
    this._logger.debug()('Downloading data...');

    // Step 1 - Download all the new data
    this._initProgressBarConfig();

    const simulatedIonicType = (
      await firstValueFrom(
        this.store.select(GlobalSelectors.selectDeviceType).pipe(filter(Boolean)),
      )
    ).simulatedIonicType;

    if (!simulatedIonicType) {
      await KeepAwake.keepAwake();
    }

    let res;
    try {
      res = await this._offlinePrefetchService.prefetchIfNeeded();
    } finally {
      if (!simulatedIonicType) {
        await KeepAwake.allowSleep();
      }
    }

    this._logger.debug()('Downloading data finished...');

    // Step 2 - Check if any errors occurred
    if (!res && this.externalConnection) {
      if (this.currentSyncUiStatus) {
        if (tryNb <= SYNC_MAX_RETRIES_ATTEMPTS) {
          this._logger.debug()('Retry Downloading Data ' + tryNb);
          await this._offlinePrefetchService.terminateSync();
          // Wait 5 seconds for retrying
          await new Promise((resolve) => setTimeout(resolve, 5000));
          return await this._downloadData((tryNb += 1));
        }
        const oldProgressPopup = this.activePopup;
        this.activePopup = this._showErrorDuringSync();

        const data = await this.activePopup.waitForCloseData();
        const resError = data.data;

        this.activePopup = oldProgressPopup;

        if (resError && resError?.confirmation) {
          return await this._downloadData(tryNb);
        }
      }

      return false;
    }

    return true;
  }
  // Recursive function...
  private async _downloading(): Promise<boolean> {
    // Step 5 - Show popup with progress bar
    this.activePopup = this._showDownloadProgressPopup();
    const data = await this.activePopup.waitForCloseData();
    const resSync = data.data;

    if (!this.externalConnection) {
      return false;
    }

    if (resSync && resSync?.error) {
      await this._offlinePrefetchService.terminateSync();
      return false;
    }

    // Step 6 - Check if popup was not closed / cancel
    if (!resSync || !resSync.confirmation) {
      // Cancel the sync select
      // Step 6.5 - Show the confirmation for canceling
      this.activePopup = this._showDownloadConfirmationPopup();
      this.confirmPopup = this.activePopup;
      const data = await this.activePopup.waitForCloseData();
      const confirmSync = data.data;
      this.confirmPopup = undefined;

      if (!confirmSync || !confirmSync.confirmation) {
        // Step 6.6 - Cancel the cancellation - resume the downloading
        return this._downloading();
      } else {
        // Step 6.7 - Confirm the cancellation
        await this._offlinePrefetchService.terminateSync();
        return false;
      }
    } else {
      // Sync finished successfully
      return true;
    }
  }

  private async _uploadData(tryNb: number = 1): Promise<boolean> {
    if (this.currentSyncUiStatus !== SyncUIStatus.GOING_ONLINE) {
      this._logger.debug()('Uploading data was aborted');
      return false;
    }
    this._logger.debug()('Uploading data...');

    // Step 1 - Upload all the new data
    this._initProgressBarConfig();

    const simulatedIonicType = (
      await firstValueFrom(
        this.store.select(GlobalSelectors.selectDeviceType).pipe(filter(Boolean)),
      )
    ).simulatedIonicType;

    if (!simulatedIonicType) {
      await KeepAwake.keepAwake();
    }

    let res;
    try {
      res = await this._offlinePushService.pushChanges();
    } finally {
      if (!simulatedIonicType) {
        await KeepAwake.allowSleep();
      }
    }

    this._logger.debug()('Uploading data finished...');

    if (!this.externalConnection) {
      return false;
    }

    // Step 2 - Check if any errors occurred
    if (!res) {
      if (this.currentSyncUiStatus) {
        if (tryNb <= SYNC_MAX_RETRIES_ATTEMPTS) {
          await this._offlinePrefetchService.terminateSync();
          // Wait 5 seconds for retrying
          await new Promise((resolve) => setTimeout(resolve, 5000));
          return await this._uploadData((tryNb += 1));
        }

        const oldProgressPopup = this.activePopup;
        this.activePopup = this._showErrorDuringSync();
        const data = await this.activePopup.waitForCloseData();
        const resError = data.data;
        this.activePopup = oldProgressPopup;

        if (resError && resError?.confirmation) {
          return await this._uploadData(tryNb);
        }
      }
      return false;
    }

    return true;
  }
  // Recursive function...
  private async _uploading(): Promise<boolean> {
    // Step 5 - Show popup with progress bar
    this.activePopup = this._showUploadProgressPopup();
    const resSync = (await this.activePopup.waitForCloseData()).data;

    if (resSync && resSync?.error) {
      await this._offlinePushService.terminatePushChanges();
      return false;
    }

    if (!this.externalConnection) {
      return false;
    }

    // Step 6 - Check if popup was not closed / cancel
    if (!resSync || !resSync.confirmation) {
      // Cancel the sync select
      // Step 6.5 - Show the confirmation for canceling
      this.activePopup = this._showUploadConfirmationPopup();
      this.confirmPopup = this.activePopup;
      const confirmSync = (await this.activePopup.waitForCloseData()).data;
      this.confirmPopup = undefined;

      if (!confirmSync || !confirmSync.confirmation) {
        // Step 6.6 - Cancel the cancellation - resume the downloading
        return this._uploading();
      } else {
        // Step 6.7 - Confirm the cancellation
        await this._offlinePushService.terminatePushChanges();
        return false;
      }
    } else {
      // Sync finished successfully
      return true;
    }
  }

  private _updateProgressBarConfigPrefetch(prefetchStatus: PrefetchStatus): void {
    const progressBar: DynamicProgressBarConfig = {
      text: this.progressBarConfig?.text ?? '',
      progress: 0,
      confirmOnFinish: true,
    };

    const progressTextMap: { [dataType in PrefetchDataTypesEnum]: string } = {
      assets2: $localize`:@@updatingAssetsThreeDots: Updating Assets...`,
      assetTypes2: $localize`:@@updatingAssetTypesThreeDots: Updating AssetTypes...`,
      organizations: $localize`:@@updatingOrganizationsThreeDots: Updating Organizations...`,
      contacts: $localize`:@@updatingContactsThreeDots: Updating Contacts...`,
      workOrders: $localize`:@@updatingWorkOrdersThreeDots: Updating Work Orders...`,
      workOrderStati: $localize`:@@updatingWorkOrderStatusThreeDots: Updating Work Order Status...`,
      workOrderTypes: $localize`:@@updatingWorkOrderTypesThreeDots: Updating Work Order Types...`,
      workOrders2: $localize`:@@updatingWorkOrdersThreeDots: Updating Work Orders...`,
      workOrderStati2: $localize`:@@updatingWorkOrderStatusThreeDots: Updating Work Order Status 2...`,
      workOrderTypes2: $localize`:@@updatingWorkOrderTypesThreeDots: Updating Work Order Types 2...`,
      files: $localize`:@@updatingFilesThreeDots: Updating Files...`,
      serviceCases: $localize`:@@updatingCases: Updating Cases...`,
      userGroups: $localize`:@@updatingUserGroups: Updating User Groups...`,
      icons: $localize`:@@updatingIcons: Updating Icons...`,
      formInstances: $localize`:@@updatingFormsInstancesThreeDots: Updating FormInstances...`,
      formTemplates: $localize`:@@updatingFormTemplatesThreeDots: Updating FormTemplates...`,
      formTemplateVersions: $localize`:@@updatingFormTemplateVersionsThreeDots: Updating FormTemplateVersions...`,
      emailStatuses: $localize`:@@updatingFormEmailStatusesThreeDots: Updating FormEmailStatuses...`,
      parts: $localize`:@@updatingPartsThreeDots: Updating Parts...`,
    };
    /**
     * This is merely an estimation as to how long a particular dataType would take to fetch relative to
     * the other prefetch items (porportional to the number of instances). Must add up to a 100.
     */
    const prefetchProgressWeights: { [dataType in PrefetchDataTypesEnum]: number } = {
      assets2: 45,
      assetTypes2: 5,
      organizations: 19,
      contacts: 2,
      workOrders: 7,
      workOrderStati: 1,
      workOrderTypes: 1,
      files: 3,
      serviceCases: 1,
      userGroups: 1,
      icons: 2,
      formInstances: 7,
      formTemplates: 0.7,
      formTemplateVersions: 0.3,
      emailStatuses: 1,
      parts: 1,
      workOrders2: 1,
      workOrderStati2: 1,
      workOrderTypes2: 1,
    };
    const summedWeights = Object.values(prefetchProgressWeights).reduce((p, c) => p + c);

    for (const dataType of Object.keys(prefetchStatus)) {
      if (prefetchStatus[dataType as keyof typeof prefetchStatus]) {
        progressBar.progress +=
          this._prefetchStatusHelper(prefetchStatus[dataType as keyof typeof prefetchStatus]) *
          prefetchProgressWeights[dataType as keyof typeof prefetchProgressWeights];
        if (prefetchStatus[dataType as keyof typeof prefetchStatus]?.prefetching) {
          progressBar.text = progressTextMap[dataType as keyof typeof progressTextMap];
        }
      }
    }

    progressBar.progress = (progressBar.progress / summedWeights) * 100;

    if (progressBar.progress >= 100) {
      progressBar.progress = 100;
      progressBar.text = $localize`:@@updateCompleted:Update completed`;
    }

    if (progressBar && progressBar.progress !== 0) {
      this.progressBarConfig = progressBar;
      this.progressBarConfigEventEmitter.next(this.progressBarConfig);
    }
  }
  private _updateProgressBarConfigPush(pushStatus: PushStatus): void {
    const progressBar: DynamicProgressBarConfig = {
      text: this.progressBarConfig?.text ?? '',
      progress: -1,
      confirmOnFinish: true,
    };

    const progressTextMap: { [dataType in ChangeTypeEnum]: string } = {
      mobileLogs: $localize`:@@preparingSynchronizationThreeDots: Preparing Synchronization...`,
      formFiles: $localize`:@@uploadingFormFilesThreeDots: Uploading Form Files...`,
      formCreations: $localize`:@@uploadingCreatedFormsThreeDots: Uploading created Forms...`,
      formUpdates: $localize`:@@uploadingUpdatedFormsThreeDots: Uploading updated Forms...`,
      formDeletions: $localize`:@@uploadingDeletedFormsThreeDots: Uploading deleted Forms...`,
    };

    const pushProgressWeights: { [dataType in ChangeTypeEnum]: number } = {
      mobileLogs: 10,
      formFiles: 60,
      formCreations: 10,
      formUpdates: 10,
      formDeletions: 10,
    };
    const summedWeights = Object.values(pushProgressWeights).reduce((p, c) => p + c);

    for (const dataType of Object.keys(pushStatus)) {
      const pushStatusValue = pushStatus[dataType as keyof PushStatus];
      if (pushStatusValue !== undefined && pushStatusValue > -1) {
        if (progressBar.progress < 0) {
          progressBar.progress = 0;
        }
        progressBar.progress +=
          pushStatusValue * pushProgressWeights[dataType as keyof typeof pushProgressWeights];
        if (pushStatusValue > -1 && pushStatusValue < 1) {
          progressBar.text = progressTextMap[dataType as keyof typeof progressTextMap];
        }
      }
    }

    progressBar.progress = (progressBar.progress / summedWeights) * 100;

    if (progressBar.progress >= 100) {
      progressBar.progress = 100;
      progressBar.text = $localize`:@@uploadCompleted:Upload Completed`;
    }

    if (progressBar && progressBar.progress > -1) {
      this.progressBarConfig = progressBar;
      this.progressBarConfigEventEmitter.next(this.progressBarConfig);
    }
  }
  private _prefetchStatusHelper(status: { [k in ActionEnum]?: number }): number {
    let total = 0;
    total += 1 * (status?.idCheck ?? 0);
    total += 5 * (status?.add ?? 0);
    total += 3 * (status?.update ?? 0);
    total += 1 * (status?.deleting ?? 0);

    // return normalized value (between 0 and 1)
    return total / 10;
  }
  private _initProgressBarConfig(): void {
    this.progressBarConfig = {
      text: $localize`:@@startingThreeDots:Starting...`,
      progress: 0,
      confirmOnFinish: true,
    };
    this.progressBarConfigEventEmitter.next(this.progressBarConfig);
  }

  private async _checkReminders(): Promise<void> {
    // if there is currently a sync ongoing, skip the reminder
    if (this.currentSyncUiStatus) {
      setTimeout(() => this._checkReminders(), SYNC_CHECK_INTERVAL);
      return;
    }

    try {
      const lastPrefetchTimeString = this.appState.getValue(LocalStorageKeys.OFFLINE_LAST_UPDATED);
      this.lastPrefetchTime = lastPrefetchTimeString ? new Date(lastPrefetchTimeString) : undefined;
      if (this.lastPrefetchTime) {
        this.noSyncInLongTime =
          this.lastPrefetchTime.getTime() + SYNC_REMINDER_LIMIT < new Date().getTime();
      }

      this.hasSyncAtLeastOnce =
        this.appState.getValue(LocalStorageKeys.OFFLINE_LAST_UPDATED) != null;

      // App is online internally and no sync in long time and has access to internet
      if (
        this.hasSyncAtLeastOnce &&
        this.noSyncInLongTime &&
        this.internalModeOnline &&
        this.externalConnection
      ) {
        this.currentSyncUiStatus = SyncUIStatus.UPDATING_DATA;
        this.activePopup = this._showReminderSyncUpdatePopup();
        const resRemindSync = (await this.activePopup.waitForCloseData()).data;

        if (!resRemindSync || !resRemindSync.confirmation) {
          return;
        }

        // Step 4 - Start the download of the data to finish
        this._downloadData()
          .then((success) => {
            if (success) {
              if (this.confirmPopup) {
                this._logger.debug()('closing dialog');
                this.confirmPopup.closeDialogModal();
              }
            } else {
              this.activePopup?.closeDialogModal({ error: true });
            }
          })
          .catch((error) => {
            this._logger.error()(error);
            this.activePopup?.closeDialogModal({ error: true });
          });

        // Steps 5 & 6
        if (!(await this._downloading())) {
          // Sync was canceled stop running the function
          return;
        }

        // App is offline internally and no been online in long time and has access to internet
      } else if (
        this.hasSyncAtLeastOnce &&
        this.noSyncInLongTime &&
        !this.internalModeOnline &&
        this.externalConnection
      ) {
        this.currentSyncUiStatus = SyncUIStatus.GOING_ONLINE;
        this.activePopup = this._showReminderSyncGoOnlinePopup();
        const resRemindGoOnline = (await this.activePopup.waitForCloseData()).data;

        if (!resRemindGoOnline || !resRemindGoOnline.confirmation) {
          return;
        }

        await this._goingOnline();

        // App is offline internally and no been online in long time and has no access to internet
      } else if (
        this.hasSyncAtLeastOnce &&
        this.noSyncInLongTime &&
        !this.internalModeOnline &&
        !this.externalConnection
      ) {
        this.currentSyncUiStatus = SyncUIStatus.GOING_ONLINE;
        this.activePopup = this._showReminderSyncGoOnlineWithoutExternalConnection();
        await this.activePopup.waitForCloseData();
        // Nothing needed to do...
      }
    } catch (error) {
      this._logger.error()('Error during checkReminders', error);
    } finally {
      this.currentSyncUiStatus = undefined;
      // schedule the next reminder
      setTimeout(() => this._checkReminders(), SYNC_CHECK_INTERVAL);
    }
  }
  //#endregion
}
