import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AssetBasic } from '@remberg/assets/common/base';
import { AssetsSortFieldsEnum, mapAssetToAssetBasic } from '@remberg/assets/common/main';
import { AssetsService } from '@remberg/assets/ui/clients';
import {
  SortDirectionEnum,
  UnknownOr,
  formatToRembergNumberString,
  isUnknownObject,
} from '@remberg/global/common/core';
import {
  ApiResponse,
  EmptyListType,
  MultiSelectFunctions,
  MultiSelectModalOptionLabel,
} from '@remberg/global/ui';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BaseMultiselectComponent,
  BaseMultiselectComponentTranslations,
} from '../base-multiselect/base-multiselect.component';

@Component({
  selector: 'app-asset-multiselect',
  templateUrl: '../base-multiselect/base-multiselect.component.html',
  styleUrls: ['../base-multiselect/base-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetMultiselectComponent extends BaseMultiselectComponent<UnknownOr<AssetBasic>> {
  @Input() public titleIconSvg: string = 'asset';
  @Input() public titleIcon = '';
  @Input() public translations: BaseMultiselectComponentTranslations = {
    title: $localize`:@@Assets:Assets`,
  };

  protected readonly emptyListType: EmptyListType = 'asset';

  private getModalOptionLabel = (asset: UnknownOr<AssetBasic>): MultiSelectModalOptionLabel => ({
    label: isUnknownObject(asset) ? '' : `${asset.assetTypeName}`,
    subLabel: isUnknownObject(asset) ? '' : formatToRembergNumberString(asset.serialNumber),
  });

  private getOptionLabel = (asset: UnknownOr<AssetBasic>): string => {
    const optionLabel = this.getModalOptionLabel(asset);
    return `${optionLabel.label} (${optionLabel.subLabel})`;
  };

  private getChipLabel = (asset: UnknownOr<AssetBasic>): string => this.getOptionLabel(asset);

  private getChipLink = (asset: UnknownOr<AssetBasic>): Observable<string> =>
    of(isUnknownObject(asset) ? '' : `/assets/detail/${asset._id}`);

  private fetchItemsWithCount = (
    search: string,
    pageSize: number,
    pageOffset: number,
  ): Observable<ApiResponse<AssetBasic[]>> =>
    this.assetsService
      .findMany({
        search,
        limit: pageSize,
        page: pageOffset,
        sortDirection: SortDirectionEnum.ASC,
        sortField: AssetsSortFieldsEnum.SERIAL_NUMBER,
      })
      .pipe(map(({ assets, count }) => ({ count, data: assets.map(mapAssetToAssetBasic) })));

  protected readonly functions: MultiSelectFunctions<UnknownOr<AssetBasic>> = {
    getChipLabel: this.getChipLabel,
    getChipLink: this.getChipLink,
    getOptionLabel: this.getOptionLabel,
    getModalOptionLabel: this.getModalOptionLabel,
    fetchListValuesWithCountFunction: this.fetchItemsWithCount,
  };

  constructor(private readonly assetsService: AssetsService) {
    super();
  }
}
