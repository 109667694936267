import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QRCode, QRCodeBasic } from '@remberg/assets/common/base';
import {
  QRCodesAssignOneBody,
  QRCodesCreateBody as QRCodesCreateManyBody,
  QRCodesDownloadManyQuery,
  QRCodesFindManyQuery,
  QRCodesFindManyResponse,
  QRCodesFindOneByLinkIdQuery,
  QRCodesFindOneQuery,
  QrCodesCreateAndAssignOneBody,
} from '@remberg/assets/common/main';
import {
  API_URL_PLACEHOLDER,
  filterEmptyProps,
  getDownloadFileName,
  programmaticallyDownloadFile,
} from '@remberg/global/ui';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QRCodesService {
  public readonly assetsUrl = `${API_URL_PLACEHOLDER}/assets/v1`;
  public readonly qrCodesUrl = `${this.assetsUrl}/qrcodes`;

  constructor(private readonly httpClient: HttpClient) {}

  public createMany(body: QRCodesCreateManyBody): Observable<QRCodeBasic[]> {
    return this.httpClient.post<QRCodeBasic[]>(this.qrCodesUrl, body);
  }

  public findOne(qrCodeId: string, query?: QRCodesFindOneQuery): Observable<QRCode> {
    const params = new HttpParams({
      fromObject: { ...(query?.populate && { populate: JSON.stringify(query.populate) }) },
    });
    return this.httpClient.get<QRCode>(`${this.qrCodesUrl}/${qrCodeId}`, { params });
  }

  public findOneByLinkId(
    qrCodeLinkId: string,
    query?: QRCodesFindOneByLinkIdQuery,
  ): Observable<QRCode> {
    const params = new HttpParams({
      fromObject: { ...(query?.populate && { populate: JSON.stringify(query.populate) }) },
    });
    return this.httpClient.get<QRCode>(`${this.qrCodesUrl}/link/${qrCodeLinkId}`, { params });
  }

  public findMany({
    filterObject,
    staticFilters,
    populate,
    ...queryParams
  }: QRCodesFindManyQuery): Observable<QRCodesFindManyResponse> {
    const params = new HttpParams({
      fromObject: {
        ...filterEmptyProps(queryParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
        ...(populate && { populate: JSON.stringify(populate) }),
      },
    });
    return this.httpClient.get<QRCodesFindManyResponse>(this.qrCodesUrl, { params });
  }

  public downloadOne(qrCodeId: string, downloadFileName: string): void {
    this.httpClient
      .get(`${this.qrCodesUrl}/${qrCodeId}/download/`, { responseType: 'blob' })
      .subscribe((data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const targetFileName = `qrcode_${getDownloadFileName(downloadFileName)}.pdf`;
        programmaticallyDownloadFile({ blob, targetFileName });
      });
  }

  public downloadMany({ filterObject, staticFilters, search }: QRCodesDownloadManyQuery): void {
    const params = new HttpParams({
      fromObject: {
        ...(search && { search }),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
      },
    });
    this.httpClient
      .get(`${this.qrCodesUrl}/download`, { params, responseType: 'blob' })
      .subscribe((data) => {
        const blob = new Blob([data], { type: 'application/zip' });
        const targetFileName = 'qrcode_collection.zip';
        programmaticallyDownloadFile({ blob, targetFileName });
      });
  }

  public scanQRCode(linkId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.qrCodesUrl}/link/${linkId}/scan`, {});
  }

  public deleteOne(qrCodeId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.qrCodesUrl}/${qrCodeId}`);
  }

  public assignOne(qrCodeId: string, body: QRCodesAssignOneBody): Observable<void> {
    return this.httpClient.patch<void>(`${this.qrCodesUrl}/${qrCodeId}/assignment`, body);
  }

  public createAndAssignOne(body: QrCodesCreateAndAssignOneBody): Observable<QRCode> {
    return this.httpClient.post<QRCode>(`${this.qrCodesUrl}/assignment`, body);
  }
}
