import { AdvancedFilterOperatorEnum, AdvancedFilterTypeEnum } from '../enums';

export const MAX_AMOUNT_OF_ADVANCED_FILTERS = 10;

const STANDARD_ADVANCED_FILTER_OPERATORS = [
  AdvancedFilterOperatorEnum.IS,
  AdvancedFilterOperatorEnum.IS_NOT,
  AdvancedFilterOperatorEnum.IS_EMPTY,
  AdvancedFilterOperatorEnum.IS_NOT_EMPTY,
];

const STANDARD_DATE_ADVANCED_FILTER_OPERATORS = [
  AdvancedFilterOperatorEnum.IS,
  AdvancedFilterOperatorEnum.FROM,
  AdvancedFilterOperatorEnum.UNTIL,
  AdvancedFilterOperatorEnum.IS_EMPTY,
  AdvancedFilterOperatorEnum.IS_NOT_EMPTY,
];

const STANDARD_DATETIME_ADVANCED_FILTER_OPERATORS = [
  AdvancedFilterOperatorEnum.FROM,
  AdvancedFilterOperatorEnum.UNTIL,
  AdvancedFilterOperatorEnum.IS_EMPTY,
  AdvancedFilterOperatorEnum.IS_NOT_EMPTY,
];

const STANDARD_RANGE_ADVANCED_FILTER_OPERATORS = [
  AdvancedFilterOperatorEnum.GREATER_EQUAL_THAN,
  AdvancedFilterOperatorEnum.LESS_EQUAL_THAN,
];

export const STANDARD_DOES_EXIST_ADVANCED_FILTER_OPERATORS = [
  AdvancedFilterOperatorEnum.IS_EMPTY,
  AdvancedFilterOperatorEnum.IS_NOT_EMPTY,
];

export const OPERATORS_FOR_ADVANCED_FILTER_TYPE_MAP: {
  [key in AdvancedFilterTypeEnum]: AdvancedFilterOperatorEnum[];
} = {
  [AdvancedFilterTypeEnum.USER_GROUP]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.USER_ROLE]: [
    AdvancedFilterOperatorEnum.IS,
    AdvancedFilterOperatorEnum.IS_NOT,
  ],
  [AdvancedFilterTypeEnum.ASSET]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.ASSET_TYPE]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.CONTACT]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.TENANT]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.ORGANIZATION]: [
    ...STANDARD_ADVANCED_FILTER_OPERATORS,
    AdvancedFilterOperatorEnum.IN,
  ],
  [AdvancedFilterTypeEnum.FORM_TEMPLATE]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.FORM_INSTANCE_STATUS]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.FORM_INSTANCE_EMAIL_STATUS]: [
    AdvancedFilterOperatorEnum.IS,
    AdvancedFilterOperatorEnum.IS_EMPTY,
  ],
  [AdvancedFilterTypeEnum.WORK_ORDER]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.WORK_ORDER_2]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.MAINTENANCE_PLAN]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.APPOINTMENT_TYPE]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.WORK_ORDER_TYPE]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.WORK_ORDER_STATUS]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.WORK_ORDER_TYPE_2]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.WORK_ORDER_STATUS_2]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.CASE]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.TICKET_2]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.CASE_CATEGORY]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.QUOTATION_REQUEST]: STANDARD_DOES_EXIST_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.QUOTATION_REQUEST_EXTERNAL_ID]: [
    AdvancedFilterOperatorEnum.CONTAINS,
    AdvancedFilterOperatorEnum.DOES_NOT_CONTAIN,
    AdvancedFilterOperatorEnum.IS_EMPTY,
    AdvancedFilterOperatorEnum.IS_NOT_EMPTY,
  ],
  [AdvancedFilterTypeEnum.SUMMARY]: STANDARD_DOES_EXIST_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.CITY]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.COUNTRY]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.PROVINCE]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.ENUM]: [
    ...STANDARD_ADVANCED_FILTER_OPERATORS,
    AdvancedFilterOperatorEnum.IN,
  ],
  [AdvancedFilterTypeEnum.STATIC_SELECT]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.BOOLEAN]: [AdvancedFilterOperatorEnum.IS],
  [AdvancedFilterTypeEnum.BOUNDING_BOX]: [AdvancedFilterOperatorEnum.IS],
  [AdvancedFilterTypeEnum.TEXT_EXISTS]: STANDARD_DOES_EXIST_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.TEXT]: [
    AdvancedFilterOperatorEnum.CONTAINS,
    AdvancedFilterOperatorEnum.DOES_NOT_CONTAIN,
    AdvancedFilterOperatorEnum.IS_EMPTY,
    AdvancedFilterOperatorEnum.IS_NOT_EMPTY,
  ],
  [AdvancedFilterTypeEnum.DATE]: STANDARD_DATE_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.REMBERG_DATE]: STANDARD_DATE_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.REMBERG_DATE_TIME]: STANDARD_DATETIME_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.REMBERG_TIMESTAMP]: STANDARD_DATETIME_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.NUMBER]: [
    ...STANDARD_ADVANCED_FILTER_OPERATORS,
    ...STANDARD_RANGE_ADVANCED_FILTER_OPERATORS,
  ],
  [AdvancedFilterTypeEnum.SUPPORT_EMAIL]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.PART]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.WORKORDER_CITY]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.WORKORDER_COUNTRY]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.WORKORDER_PROVINCE]: STANDARD_ADVANCED_FILTER_OPERATORS,
  [AdvancedFilterTypeEnum.CASE_CASTEGORY]: STANDARD_ADVANCED_FILTER_OPERATORS,
};

export const OPERATORS_WITH_NO_VALUE_TO_SELECT: AdvancedFilterOperatorEnum[] = [
  AdvancedFilterOperatorEnum.IS_EMPTY,
  AdvancedFilterOperatorEnum.IS_NOT_EMPTY,
];

export const NON_REPEATABLE_ADVANCED_FILTER_TYPES: AdvancedFilterTypeEnum[] = [
  AdvancedFilterTypeEnum.BOOLEAN,
];

export const ADVANCED_FILTER_ARRAY_SEPARATOR = ',';
