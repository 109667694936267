import { inject } from '@angular/core';
import { AssetRaw } from '@remberg/assets/common/main';
import { ContactRaw, OrganizationRaw } from '@remberg/crm/common/main';
import { CONTACTS_OFFLINE_SERVICE } from '@remberg/crm/ui/clients';
import { CreationTypeEnum, UnknownOr } from '@remberg/global/common/core';
import { LogService, SQLQueryParams, SyncDataTypesEnum } from '@remberg/global/ui';
import { UserGroup } from '@remberg/users/common/main';
import { WorkOrderStatus, WorkOrderType } from '@remberg/work-orders/common/base';
import {
  Case,
  WorkOrderAdvancedFilterConfigKeys,
  WorkOrderFilterFieldEnum,
  WorkOrderPopulateEnum,
  WorkOrderRaw,
  WorkOrderSortFieldEnum,
} from '@remberg/work-orders/common/main';
import { populateArray } from '../../sqlDB.service';

export enum WorkOrderOfflineColumnNamesEnum {
  COUNTER = 'counter',
  PRIORITY = 'priority',
  SUBJECT = 'subject',
  EXTERNAL_REFERENCE = 'externalReference',
  ESTIMATED_DURATION = 'estimatedDuration',
  STATUS_ID = 'statusId',
  STATUS_IS_COMPLETED = 'statusIsCompleted',
  STATUS_ORDER = 'statusOrder',
  TYPE_ID = 'typeId',
  TYPE_ORDER = 'typeOrder',
  DUE_DATE = 'dueDate',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',

  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  CITY = 'city',
  ZIP_POST_CODE = 'zipPostCode',
  STREET = 'street',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',

  CREATED_BY_ID = 'createdById',
  CREATED_BY_TYPE = 'createdByType',
  UPDATE_BY_ID = 'updateById',
  UPDATED_BY_TYPE = 'updatedByType',

  TOUCHED = 'touched',

  PLANNING_ASSIGNED_CONTACT_IDS = 'planningAssignedContactIds',
  PLANNING_ASSIGNED_GROUP_IDS = 'planningAssignedGroupIds',
  PLANNING_START_DATE = 'planningStartDate',
  PLANNING_END_DATE = 'planningEndDate',
  PLANNING_IS_FULL_DAY = 'planningIsFullDay',

  RESPONSIBLE_CONTACT_ID = 'responsibleContactId',
  RESPONSIBLE_GROUP_ID = 'responsibleGroupId',

  RELATED_CASE_ID = 'relatedCaseId',
  RELATED_ORGANIZATION_ID = 'relatedOrganizationId',
  RELATED_ASSET_IDS = 'relatedAssetIds',
  RELATED_CONTACT_IDS = 'relatedContactIds',

  PARENT_WORK_ORDER_ID = 'parentWorkOrderId',
}

export enum WorkOrderOfflinePopulateEnum {
  TYPE = WorkOrderPopulateEnum.TYPE,
  STATUS = WorkOrderPopulateEnum.STATUS,
  PARENT_WORK_ORDER = WorkOrderPopulateEnum.PARENT_WORK_ORDER,
  RELATED_CONTACTS = WorkOrderPopulateEnum.RELATED_CONTACTS,
  RESPONSIBLE_CONTACT = WorkOrderPopulateEnum.RESPONSIBLE_CONTACT,
  CREATED_BY = WorkOrderPopulateEnum.CREATED_BY,
  UPDATED_BY = WorkOrderPopulateEnum.UPDATED_BY,
  RESPONSIBLE_GROUP = WorkOrderPopulateEnum.RESPONSIBLE_GROUP,
  RELATED_ASSETS = WorkOrderPopulateEnum.RELATED_ASSETS,
  RELATED_ORGANIZATION = WorkOrderPopulateEnum.RELATED_ORGANIZATION,
  RELATED_CASE = WorkOrderPopulateEnum.RELATED_CASE,
  PLANNING_ASSIGNED_CONTACTS = 'planningAssignedContacts',
  PLANNING_ASSIGNED_GROUPS = 'planningAssignedGroups',
}

export const SORT_FIELD_TO_COLUMN_NAME_MAP: Record<
  WorkOrderSortFieldEnum,
  WorkOrderOfflineColumnNamesEnum
> = {
  [WorkOrderSortFieldEnum.COUNTER]: WorkOrderOfflineColumnNamesEnum.COUNTER,
  [WorkOrderSortFieldEnum.PRIORITY]: WorkOrderOfflineColumnNamesEnum.PRIORITY,
  [WorkOrderSortFieldEnum.SUBJECT]: WorkOrderOfflineColumnNamesEnum.SUBJECT,
  [WorkOrderSortFieldEnum.ESTIMATED_DURATION]: WorkOrderOfflineColumnNamesEnum.ESTIMATED_DURATION,
  [WorkOrderSortFieldEnum.EXTERNAL_REFERENCE]: WorkOrderOfflineColumnNamesEnum.EXTERNAL_REFERENCE,
  [WorkOrderSortFieldEnum.STATUS]: WorkOrderOfflineColumnNamesEnum.STATUS_ID,
  [WorkOrderSortFieldEnum.TYPE]: WorkOrderOfflineColumnNamesEnum.TYPE_ID,
  [WorkOrderSortFieldEnum.DUE_DATE]: WorkOrderOfflineColumnNamesEnum.DUE_DATE,
  [WorkOrderSortFieldEnum.CREATED_AT]: WorkOrderOfflineColumnNamesEnum.CREATED_AT,
  [WorkOrderSortFieldEnum.UPDATED_AT]: WorkOrderOfflineColumnNamesEnum.UPDATED_AT,
  [WorkOrderSortFieldEnum.COUNTRY]: WorkOrderOfflineColumnNamesEnum.COUNTRY,
  [WorkOrderSortFieldEnum.COUNTRY_PROVINCE]: WorkOrderOfflineColumnNamesEnum.COUNTRY_PROVINCE,
  [WorkOrderSortFieldEnum.CITY]: WorkOrderOfflineColumnNamesEnum.CITY,
  [WorkOrderSortFieldEnum.ZIP_POST_CODE]: WorkOrderOfflineColumnNamesEnum.ZIP_POST_CODE,
  [WorkOrderSortFieldEnum.STREET]: WorkOrderOfflineColumnNamesEnum.STREET,
  [WorkOrderSortFieldEnum.PLANNING_END_DATE]: WorkOrderOfflineColumnNamesEnum.PLANNING_END_DATE,
  [WorkOrderSortFieldEnum.PLANNING_START_DATE]: WorkOrderOfflineColumnNamesEnum.PLANNING_START_DATE,
};

export type WorkOrderOfflinePopulates = {
  type?: WorkOrderType;
  status: WorkOrderStatus;
  parentWorkOrder?: WorkOrderRaw;
  relatedContacts?: UnknownOr<ContactRaw>[];
  responsibleContact?: ContactRaw;
  createdBy?: ContactRaw;
  updatedBy?: ContactRaw;
  responsibleGroup?: UserGroup;
  relatedAssets?: UnknownOr<AssetRaw>[];
  relatedOrganization?: OrganizationRaw;
  relatedCase?: Case;
  planningContacts?: UnknownOr<ContactRaw>[];
  planningUserGroups?: UnknownOr<UserGroup>[];
};

export type WorkOrderOfflinePopulated = WorkOrderRaw & WorkOrderOfflinePopulates;

export const WORK_ORDER2_SQL_QUERY_PARAMS: SQLQueryParams<
  WorkOrderOfflineColumnNamesEnum,
  WorkOrderOfflinePopulateEnum,
  keyof WorkOrderOfflinePopulates
> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.WORKORDERS2,
  columns: {
    [WorkOrderOfflineColumnNamesEnum.RELATED_ASSET_IDS]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.relatedAssetIds?.join(','),
    },
    [WorkOrderOfflineColumnNamesEnum.RELATED_ORGANIZATION_ID]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.relatedOrganizationId,
    },
    [WorkOrderOfflineColumnNamesEnum.PRIORITY]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.priority,
    },
    [WorkOrderOfflineColumnNamesEnum.ESTIMATED_DURATION]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrderRaw) => val.estimatedDuration,
    },
    [WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_CONTACT_ID]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.responsibleContactId,
    },
    [WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_GROUP_ID]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.responsibleGroupId,
    },
    [WorkOrderOfflineColumnNamesEnum.STATUS_IS_COMPLETED]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrderRaw) => (val.statusValue.isCompleted ? 1 : 0),
    },
    [WorkOrderOfflineColumnNamesEnum.STATUS_ID]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.statusValue.statusId,
    },
    [WorkOrderOfflineColumnNamesEnum.STATUS_ORDER]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrderRaw) => val.statusValue.order,
    },
    [WorkOrderOfflineColumnNamesEnum.TYPE_ID]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.typeValue?.typeId,
    },
    [WorkOrderOfflineColumnNamesEnum.TYPE_ORDER]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrderRaw) => val.typeValue?.order,
    },
    [WorkOrderOfflineColumnNamesEnum.RELATED_CASE_ID]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.relatedCaseId,
    },
    [WorkOrderOfflineColumnNamesEnum.DUE_DATE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.dueDate,
    },
    [WorkOrderOfflineColumnNamesEnum.CREATED_AT]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.createdAt,
    },
    [WorkOrderOfflineColumnNamesEnum.UPDATED_AT]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.updatedAt,
    },
    [WorkOrderOfflineColumnNamesEnum.SUBJECT]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.subject?.toLowerCase()?.trim(),
      sortNoCase: true,
    },
    [WorkOrderOfflineColumnNamesEnum.COUNTER]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrderRaw) => val.counter,
    },
    [WorkOrderOfflineColumnNamesEnum.EXTERNAL_REFERENCE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.externalReference,
    },
    [WorkOrderOfflineColumnNamesEnum.CITY]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.location?.city?.toLowerCase()?.trim(),
    },
    [WorkOrderOfflineColumnNamesEnum.COUNTRY]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.location?.country?.toLowerCase()?.trim(),
    },
    [WorkOrderOfflineColumnNamesEnum.COUNTRY_PROVINCE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.location?.countryProvince?.toLowerCase()?.trim(),
    },
    [WorkOrderOfflineColumnNamesEnum.ZIP_POST_CODE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.location?.zipPostCode?.toLowerCase()?.trim(),
    },
    [WorkOrderOfflineColumnNamesEnum.LATITUDE]: {
      type: 'FLOAT',
      valueFunction: (val: WorkOrderRaw) => val.location?.latitude,
    },
    [WorkOrderOfflineColumnNamesEnum.LONGITUDE]: {
      type: 'FLOAT',
      valueFunction: (val: WorkOrderRaw) => val.location?.longitude,
    },
    [WorkOrderOfflineColumnNamesEnum.STREET]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.location?.street?.toLowerCase()?.trim(),
    },
    [WorkOrderOfflineColumnNamesEnum.TOUCHED]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrderRaw) => (val.isTouchedByUser ? 1 : 0),
    },
    [WorkOrderOfflineColumnNamesEnum.CREATED_BY_ID]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.createdById,
    },
    [WorkOrderOfflineColumnNamesEnum.CREATED_BY_TYPE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.createdByType,
    },
    [WorkOrderOfflineColumnNamesEnum.UPDATE_BY_ID]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.updatedById,
    },
    [WorkOrderOfflineColumnNamesEnum.UPDATED_BY_TYPE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.updatedByType,
    },
    [WorkOrderOfflineColumnNamesEnum.RELATED_CONTACT_IDS]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.relatedContactIds?.join(','),
    },
    [WorkOrderOfflineColumnNamesEnum.PARENT_WORK_ORDER_ID]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.parentWorkOrderId,
    },
    [WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_CONTACT_IDS]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.planning?.assignedContactIds?.join(','),
    },
    [WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_GROUP_IDS]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.planning?.assignedGroupIds?.join(','),
    },
    [WorkOrderOfflineColumnNamesEnum.PLANNING_START_DATE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.planning?.startDate,
    },
    [WorkOrderOfflineColumnNamesEnum.PLANNING_END_DATE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderRaw) => val.planning?.endDate,
    },
    [WorkOrderOfflineColumnNamesEnum.PLANNING_IS_FULL_DAY]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrderRaw) => (val.planning?.isFullDay ? 1 : 0),
    },
  },
  populates: {
    [WorkOrderOfflinePopulateEnum.PARENT_WORK_ORDER]: {
      populateDataType: SyncDataTypesEnum.WORKORDERS2,
      sourceKey: WorkOrderOfflineColumnNamesEnum.PARENT_WORK_ORDER_ID,
      targetKey: 'parentWorkOrder',
    },
    [WorkOrderOfflinePopulateEnum.RELATED_ASSETS]: {
      targetKey: 'relatedAssets',
      populateFunction: async (val: WorkOrderRaw, logger: LogService) =>
        populateArray(SyncDataTypesEnum.ASSETS2, val.relatedAssetIds, true, logger),
    },
    [WorkOrderOfflinePopulateEnum.RELATED_ORGANIZATION]: {
      populateDataType: SyncDataTypesEnum.ORGANIZATIONS,
      sourceKey: WorkOrderOfflineColumnNamesEnum.RELATED_ORGANIZATION_ID,
      targetKey: 'relatedOrganization',
    },
    [WorkOrderOfflinePopulateEnum.RELATED_CONTACTS]: {
      targetKey: 'relatedContacts',
      populateFunction: (val: WorkOrderRaw, logger: LogService) =>
        populateArray(SyncDataTypesEnum.CONTACTS, val.relatedContactIds, true, logger),
    },
    [WorkOrderOfflinePopulateEnum.RESPONSIBLE_CONTACT]: {
      populateDataType: SyncDataTypesEnum.CONTACTS,
      sourceKey: WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_CONTACT_ID,
      targetKey: 'responsibleContact',
    },
    [WorkOrderOfflinePopulateEnum.RESPONSIBLE_GROUP]: {
      populateDataType: SyncDataTypesEnum.USERGROUPS,
      sourceKey: WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_GROUP_ID,
      targetKey: 'responsibleGroup',
    },
    [WorkOrderOfflinePopulateEnum.RELATED_CASE]: {
      populateDataType: SyncDataTypesEnum.SERVICECASES,
      sourceKey: WorkOrderOfflineColumnNamesEnum.RELATED_CASE_ID,
      targetKey: 'relatedCase',
    },
    [WorkOrderOfflinePopulateEnum.CREATED_BY]: {
      targetKey: 'createdBy',
      populateFunction: async (val: WorkOrderRaw) => {
        if (!val.createdById) return;

        switch (val.createdByType) {
          case CreationTypeEnum.USER: {
            const contactsOfflineService = inject(CONTACTS_OFFLINE_SERVICE);
            return contactsOfflineService.tryGetInstance(val.createdById);
          }
        }

        // Other creation types not supported in offline mode for now
        return undefined;
      },
    },
    [WorkOrderOfflinePopulateEnum.UPDATED_BY]: {
      targetKey: 'updatedBy',
      populateFunction: async (val: WorkOrderRaw) => {
        if (!val.updatedById) return;

        switch (val.updatedByType) {
          case CreationTypeEnum.USER: {
            const contactsOfflineService = inject(CONTACTS_OFFLINE_SERVICE);
            return contactsOfflineService.tryGetInstance(val.updatedById);
          }
        }

        // Other creation types not supported in offline mode for now
        return undefined;
      },
    },
    [WorkOrderOfflinePopulateEnum.STATUS]: {
      populateDataType: SyncDataTypesEnum.WORKORDERSTATI2,
      sourceKey: WorkOrderOfflineColumnNamesEnum.STATUS_ID,
      targetKey: 'status',
    },
    [WorkOrderOfflinePopulateEnum.TYPE]: {
      populateDataType: SyncDataTypesEnum.WORKORDERTYPES2,
      sourceKey: WorkOrderOfflineColumnNamesEnum.TYPE_ID,
      targetKey: 'type',
    },
    [WorkOrderOfflinePopulateEnum.PLANNING_ASSIGNED_CONTACTS]: {
      targetKey: 'planningContacts',
      populateFunction: (val: WorkOrderRaw, logger: LogService) =>
        populateArray(SyncDataTypesEnum.CONTACTS, val.planning?.assignedContactIds, true, logger),
    },
    [WorkOrderOfflinePopulateEnum.PLANNING_ASSIGNED_GROUPS]: {
      targetKey: 'planningUserGroups',
      populateFunction: (val: WorkOrderRaw, logger: LogService) =>
        populateArray(SyncDataTypesEnum.USERGROUPS, val.planning?.assignedGroupIds, true, logger),
    },
  },
};

export const WORK_ORDER_FILTER_ENUM_TO_COLUMN_NAME: Record<
  WorkOrderAdvancedFilterConfigKeys,
  WorkOrderOfflineColumnNamesEnum | 'CUSTOM_LOGIC'
> = {
  [WorkOrderFilterFieldEnum.RELATED_ASSET]: WorkOrderOfflineColumnNamesEnum.RELATED_ASSET_IDS,
  [WorkOrderFilterFieldEnum.RELATED_ORGANIZATION]:
    WorkOrderOfflineColumnNamesEnum.RELATED_ORGANIZATION_ID,
  [WorkOrderFilterFieldEnum.PRIORITY]: WorkOrderOfflineColumnNamesEnum.PRIORITY,
  [WorkOrderFilterFieldEnum.STATUS]: WorkOrderOfflineColumnNamesEnum.STATUS_ID,
  [WorkOrderFilterFieldEnum.STATUS_COMPLETED]: WorkOrderOfflineColumnNamesEnum.STATUS_IS_COMPLETED,
  [WorkOrderFilterFieldEnum.TYPE]: WorkOrderOfflineColumnNamesEnum.TYPE_ID,
  [WorkOrderFilterFieldEnum.RELATED_CASE]: WorkOrderOfflineColumnNamesEnum.RELATED_CASE_ID,
  [WorkOrderFilterFieldEnum.RESPONSIBLE_GROUP]:
    WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_GROUP_ID,
  [WorkOrderFilterFieldEnum.MAINTENANCE_PLAN]: WorkOrderOfflineColumnNamesEnum.CREATED_BY_ID,
  [WorkOrderFilterFieldEnum.EXTERNAL_REFERENCE]: WorkOrderOfflineColumnNamesEnum.EXTERNAL_REFERENCE,
  [WorkOrderFilterFieldEnum.TOUCHED]: WorkOrderOfflineColumnNamesEnum.TOUCHED,
  [WorkOrderFilterFieldEnum.RESPONSIBLE_CONTACT]:
    WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_CONTACT_ID,
  [WorkOrderFilterFieldEnum.PARENT_WORK_ORDER]:
    WorkOrderOfflineColumnNamesEnum.PARENT_WORK_ORDER_ID,
  [WorkOrderFilterFieldEnum.RELATED_CONTACT]: WorkOrderOfflineColumnNamesEnum.RELATED_CONTACT_IDS,
  [WorkOrderFilterFieldEnum.CREATED_AT]: WorkOrderOfflineColumnNamesEnum.CREATED_AT,
  [WorkOrderFilterFieldEnum.UPDATED_AT]: WorkOrderOfflineColumnNamesEnum.UPDATED_AT,
  [WorkOrderFilterFieldEnum.DUE_DATE]: WorkOrderOfflineColumnNamesEnum.DUE_DATE,
  [WorkOrderFilterFieldEnum.CITY]: WorkOrderOfflineColumnNamesEnum.CITY,
  [WorkOrderFilterFieldEnum.STREET]: WorkOrderOfflineColumnNamesEnum.STREET,
  [WorkOrderFilterFieldEnum.COUNTRY]: WorkOrderOfflineColumnNamesEnum.COUNTRY,
  [WorkOrderFilterFieldEnum.COUNTRY_PROVINCE]: WorkOrderOfflineColumnNamesEnum.COUNTRY_PROVINCE,
  [WorkOrderFilterFieldEnum.ZIP_CODE]: WorkOrderOfflineColumnNamesEnum.ZIP_POST_CODE,
  [WorkOrderFilterFieldEnum.PLANNING_ASSIGNED_CONTACT]:
    WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_CONTACT_IDS,
  [WorkOrderFilterFieldEnum.PLANNING_ASSIGNED_GROUP]:
    WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_GROUP_IDS,
  [WorkOrderFilterFieldEnum.PLANNING_START_DATE]:
    WorkOrderOfflineColumnNamesEnum.PLANNING_START_DATE,
  [WorkOrderFilterFieldEnum.PLANNING_END_DATE]: WorkOrderOfflineColumnNamesEnum.PLANNING_END_DATE,
  // These filter types have a custom logic
  [WorkOrderFilterFieldEnum.RELATED_ASSETS]: 'CUSTOM_LOGIC',
  [WorkOrderFilterFieldEnum.INVOLVED_CONTACT]: 'CUSTOM_LOGIC',
  [WorkOrderFilterFieldEnum.FAMILY]: 'CUSTOM_LOGIC',
  [WorkOrderFilterFieldEnum.MY_GROUPS]: 'CUSTOM_LOGIC',
  [WorkOrderFilterFieldEnum.BOUNDING_BOX]: 'CUSTOM_LOGIC',
  [WorkOrderFilterFieldEnum.ID]: 'CUSTOM_LOGIC',
  [WorkOrderFilterFieldEnum.CREATED_BY]: 'CUSTOM_LOGIC',
  [WorkOrderFilterFieldEnum.UPDATE_BY]: 'CUSTOM_LOGIC',
};

export const WORK_ORDER_DATE_COLUMNS = [WorkOrderFilterFieldEnum.DUE_DATE];
