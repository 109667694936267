// NOTE - Be careful with importing in this file
// It is used by web workers that run on different
// threads and thus can not import some modules that the web workers cannot handle:
// * Known incompatible imports : localize

import { LogService } from '../services';
import {
  IDBPrefetchChunkSize,
  IDBPrefetchChunkSizeAssets,
  IDBPrefetchChunkSizeForms,
} from './app.config';
import { LocalStorageKeys } from './enums';

export enum SyncDataTypesEnum {
  ASSETS2 = 'assets2',
  ASSETTYPES2 = 'assetTypes2',
  CONTACTS = 'contacts',
  ORGANIZATIONS = 'organizations',
  WORKORDERS = 'workOrders',
  WORKORDERSTATI = 'workOrderStati',
  WORKORDERTYPES = 'workOrderTypes',
  WORKORDERS2 = 'workOrders2',
  WORKORDERSTATI2 = 'workOrderStati2',
  WORKORDERTYPES2 = 'workOrderTypes2',
  FILES = 'files',
  USERGROUPS = 'userGroups',
  SERVICECASES = 'serviceCases',
  FORMINSTANCES = 'formInstances',
  FORMTEMPLATES = 'formTemplates',
  FORMTEMPLATEVERSIONS = 'formTemplateVersions',
  EMAILSTATUSES = 'emailStatuses',
  PARTS = 'parts',
}

export enum PrefetchOnlyDataTypesEnum {
  ICONS = 'icons',
}

export enum SyncUIStatus {
  GOING_OFFLINE = 'goingOffline',
  UPDATING_DATA = 'updatingData',
  GOING_ONLINE = 'goingOnline',
  GOING_OFFLINE_OR_SYNC = 'goingOfflineOrSync',
}

export enum ButtonActions {
  PROCEED_ANYWAYS = 'proceedAnyways',
  ABORT = 'abort',
  CONFIRM = 'confirm',
  FINISH = 'finish',
  UPDATE_DATA = 'updateData',
  GO_OFFLINE = 'goOffline',
  RETRY = 'retry',
}

export enum ActionEnum {
  ID_CHECK = 'idCheck',
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'deleting',
}

export enum ChangeTypeEnum {
  MOBILE_LOGS = 'mobileLogs',
  FORM_FILES = 'formFiles',
  FORM_CREATIONS = 'formCreations',
  FORM_UPDATES = 'formUpdates',
  FORM_DELETIONS = 'formDeletions',
}

export type PrefetchDataTypesEnum = SyncDataTypesEnum | PrefetchOnlyDataTypesEnum;
export type PrefetchStatus = {
  [key in PrefetchDataTypesEnum]: { [k in ActionEnum]?: number } & { prefetching?: boolean };
};
export type LastUpdateTimeStamps = { [key in PrefetchDataTypesEnum]: string };
export type PushStatus = { [key in ChangeTypeEnum]?: number };

export const batchSizes: { [type in SyncDataTypesEnum]: number } = {
  assets2: IDBPrefetchChunkSizeAssets,
  assetTypes2: IDBPrefetchChunkSize,
  contacts: IDBPrefetchChunkSize,
  organizations: IDBPrefetchChunkSize,
  files: IDBPrefetchChunkSize,
  workOrders: IDBPrefetchChunkSize,
  workOrderStati: IDBPrefetchChunkSize,
  workOrderTypes: IDBPrefetchChunkSize,
  workOrders2: IDBPrefetchChunkSize,
  workOrderStati2: IDBPrefetchChunkSize,
  workOrderTypes2: IDBPrefetchChunkSize,
  serviceCases: IDBPrefetchChunkSize,
  userGroups: IDBPrefetchChunkSize,
  formInstances: IDBPrefetchChunkSizeForms, // special handling!
  formTemplates: IDBPrefetchChunkSize,
  formTemplateVersions: IDBPrefetchChunkSize,
  emailStatuses: IDBPrefetchChunkSize,
  parts: IDBPrefetchChunkSize,
};

export const timestampLocalStorageKeyMap: { [type in PrefetchDataTypesEnum]: LocalStorageKeys } = {
  formInstances: LocalStorageKeys.OFFLINE_LAST_UPDATED_FORMINSTANCES,
  assets2: LocalStorageKeys.OFFLINE_LAST_UPDATED_ASSETS2,
  assetTypes2: LocalStorageKeys.OFFLINE_LAST_UPDATED_ASSETTYPES2,
  contacts: LocalStorageKeys.OFFLINE_LAST_UPDATED_CONTACTS,
  organizations: LocalStorageKeys.OFFLINE_LAST_UPDATED_ORGANIZATIONS,
  files: LocalStorageKeys.OFFLINE_LAST_UPDATED_FILES,
  workOrders: LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERS,
  workOrderStati: LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERSTATI,
  workOrderTypes: LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERTYPES,
  workOrders2: LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERS2,
  workOrderStati2: LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERSTATI2,
  workOrderTypes2: LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERTYPES2,
  serviceCases: LocalStorageKeys.OFFLINE_LAST_UPDATED_SERVICECASES,
  userGroups: LocalStorageKeys.OFFLINE_LAST_UPDATED_USERGROUPS,
  icons: LocalStorageKeys.OFFLINE_LAST_UPDATED_ICONS,
  formTemplates: LocalStorageKeys.OFFLINE_LAST_UPDATED_FORMTEMPLATES,
  formTemplateVersions: LocalStorageKeys.OFFLINE_LAST_UPDATED_FORMTEMPLATEVERSIONS,
  emailStatuses: LocalStorageKeys.OFFLINE_LAST_UPDATED_EMAILSTATUSES,
  parts: LocalStorageKeys.OFFLINE_LAST_UPDATED_PARTS,
};

export interface ChangedIDsPayload {
  updateIDs: string[];
  deleteIDs: string[];
  createIDs: string[];
  timestamp: string;
}

export interface ChangedIdsPayload2 {
  updateIds: string[];
  deleteIds: string[];
  createIds: string[];
  timestamp: string;
}

export interface SQLQueryParams<
  Column extends string = never,
  Populate extends string = never,
  TargetKey extends string = never,
> {
  idString: string;
  tableName: SyncDataTypesEnum;
  columns?: Record<
    Column extends string ? Column : never,
    {
      type: string;
      valueFunction: (val: any) => any;
      sortNoCase?: boolean;
      isSearchColumn?: boolean;
    }
  >;
  populates?: Record<
    Populate extends string ? Populate : never,
    | {
        targetKey: TargetKey;
        populateFunction: (val: any, logger: LogService) => Promise<any>;
      }
    | {
        populateDataType: SyncDataTypesEnum;
        sourceKey: Column;
        targetKey: TargetKey;
      }
  >;
}

export type SQLDataValues = any[];

export enum SQLSortDirection {
  DESC = 'DESC',
  ASC = 'ASC',
  ASC_NULLS_LAST = 'ASC NULLS LAST',
  DESC_NULLS_FIRST = 'DESC NULLS FIRST',
}

export function stringToSQLSortDirection(direction?: string): SQLSortDirection | undefined {
  return direction === 'asc'
    ? SQLSortDirection.ASC
    : direction === 'desc'
      ? SQLSortDirection.DESC
      : undefined;
}

export type OfflinePopulateType<T = void> = T extends string
  ? Record<T, boolean>
  : Record<string, boolean> | boolean;
