import { Router } from '@angular/router';
import { MainRoutes } from '@remberg/global/ui';
import { IamForbiddenMessageEnum } from '@remberg/users/common/main';
import { ToastrService } from 'ngx-toastr';

interface IamForbiddenMessageHandlerPayload {
  message: string | IamForbiddenMessageEnum;
  toastr: ToastrService;
  router: Router;
  isPortal: boolean;
  email: string;
}

export function handleIamForbiddenMessage({
  message,
  toastr,
  router,
  isPortal,
  email,
}: IamForbiddenMessageHandlerPayload): void {
  switch (message) {
    case IamForbiddenMessageEnum.USER_NOT_ACTIVATED:
      toastr.error($localize`:@@userAccountNotYetActivated: User account not yet activated!`);
      break;
    case IamForbiddenMessageEnum.MOBILE_APPLICATION_DISABLED:
      toastr.error(
        $localize`:@@mobileApplicationUseIsDisabled: Mobile application use is disabled.`,
      );
      break;
    case IamForbiddenMessageEnum.PASSWORD_EXPIRED: {
      const portalSegment = isPortal ? 'portal/' : '';
      void router.navigate([`/${portalSegment}${MainRoutes.FORGOT}`], {
        queryParams: { passwordExpired: email },
        queryParamsHandling: 'merge',
      });
      break;
    }
    case IamForbiddenMessageEnum.TENANT_DEACTIVATED:
      toastr.error(
        $localize`:@@theOrganizationAccountIsNotActivatedAnymore:The organization account is not activated anymore.`,
      );
      break;
    case IamForbiddenMessageEnum.PORTAL_NOT_ENABLED:
      toastr.error($localize`:@@portalFeatureIsNotEnabled: Portal feature is not enabled.`);
      break;
    case IamForbiddenMessageEnum.USER_IS_SUSPENDED:
      toastr.error($localize`:@@userIsSuspended:User is suspended.`);
      break;
    default:
      toastr.error($localize`:@@somethingWentWrong: Something went wrong`);
      break;
  }
}
