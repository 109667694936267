/* eslint-disable no-restricted-syntax */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { ProductCompatibility } from '@remberg/assets/common/main';
import { ContactCompatibility, OrganizationCompatibility } from '@remberg/crm/common/main';
import { UnknownOr } from '@remberg/global/common/core';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
} from '@remberg/global/ui';
import {
  Activity,
  CaseExportResponse,
  CasePopulateFieldEnum,
  CaseSinglePDFOptions,
  Category,
  PublicCaseRequestPayload,
  ServiceCase,
  ServiceCasePayload,
  ServiceCaseStatus,
  TicketTree,
  TicketTreeNode,
} from '@remberg/tickets/common/main';
import findLast from 'lodash/findLast';
import { Observable, Subject, from, of, shareReplay, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { CaseAdvancedFilterQuery, CaseAdvancedStaticFilter } from './service-case.definitions';
import {
  SERVICE_CASE_OFFLINE_SERVICE,
  ServiceCaseOfflineServiceInterface,
} from './service-case.offline.service.interface';

const MAX_NUMBER_OF_CONVERSATION_ACTIVITIES = 7;

@Injectable({
  providedIn: 'root',
})
export class ServiceCaseService implements OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  public readonly serviceCasesUrl = `${API_URL_PLACEHOLDER}/tickets`;

  private assetsSelection = new Subject<UnknownOr<ProductCompatibility>[]>();
  private senderSelection = new Subject<UnknownOr<OrganizationCompatibility>>();
  private contactSelection = new Subject<UnknownOr<ContactCompatibility>>();

  private readonly serviceCaseCache = new Map<string, Observable<ServiceCase>>();

  constructor(
    private http: HttpClient,
    private logger: LogService,
    @Inject(SERVICE_CASE_OFFLINE_SERVICE)
    private serviceCaseOfflineService: ServiceCaseOfflineServiceInterface,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
  ) {}

  private getServiceCasesBase(
    populate?: boolean | ServiceCase,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
    filterQuery?: CaseAdvancedFilterQuery,
    staticFilters?: CaseAdvancedStaticFilter[],
    ids?: string[],
  ): Observable<ApiResponse<ServiceCase[]>> {
    let params = new HttpParams();

    if (populate !== undefined && populate && typeof populate === 'boolean') {
      params = params.set('populate', populate.toString());
    }
    if (populate && (populate as ServiceCase).sender) {
      params = params.set('populateSender', 'true');
    }
    if (populate && (populate as ServiceCase).receiver) {
      params = params.set('populateReceiver', 'true');
    }
    if (populate && (populate as ServiceCase).category) {
      params = params.set('populateCategory', 'true');
    }
    if (populate && (populate as ServiceCase).assignee) {
      params = params.set('populateAssignee', 'true');
    }
    if (populate && (populate as ServiceCase).followers) {
      params = params.set('populateFollowers', 'true');
    }
    if (populate && (populate as ServiceCase).requestingContact) {
      params = params.set('populateRequestingContact', 'true');
    }
    if (populate && (populate as ServiceCase).activities) {
      params = params.set('populateActivities', 'true');
    }
    if (populate && (populate as ServiceCase).assets) {
      params = params.set('populateAssets', 'true');
    }
    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchValue) {
      params = params.set('search', encodeURIComponent(String(searchValue)));
    }
    if (filterValue) {
      params = params.set('filter', JSON.stringify(filterValue));
    }
    if (filterQuery) {
      params = params.set('filterQuery', JSON.stringify(filterQuery));
    }
    if (staticFilters) {
      params = params.set('staticFilters', JSON.stringify(staticFilters));
    }
    if (ids !== undefined) {
      params = params.set('instance_ids', JSON.stringify(ids));
    }

    // TODO STEVEN remove again after testing on production - just for performance testing
    const query = new URLSearchParams(window.location.search);
    const usePrefix = query.get('usePrefix') !== null;
    if (usePrefix) {
      params = params.set('usePrefix', 'true');
    }
    const searchLimit = query.get('searchLimit');
    if (searchLimit) {
      params = params.set('searchLimit', searchLimit);
    }

    return this.http.get<any>(this.serviceCasesUrl, { params: params });
  }

  public getServiceCasesByIds(ids: string[], populate?: boolean | any): Observable<ServiceCase[]> {
    return this.getServiceCasesBase(
      populate,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      ids,
    ).pipe(map((res) => res.data));
  }

  public getServiceCasesWithAdvancedFilter(
    populateFields: CasePopulateFieldEnum[],
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterQuery?: CaseAdvancedFilterQuery,
    staticFilters?: CaseAdvancedStaticFilter[],
  ): Observable<ApiResponse<ServiceCase[]>> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online service case instances request...');

      const populate: Record<string, boolean> = {};
      for (const populateField of populateFields) {
        populate[populateField] = true;
      }

      return this.getServiceCasesBase(
        populate as any,
        pageSize,
        pageIndex,
        sortDirection,
        sortField,
        searchValue,
        undefined,
        filterQuery,
        staticFilters,
      ).pipe(map((res) => new ApiResponse<ServiceCase[]>(res.data, res.count)));
    } else {
      this.logger.debug()('Offline service case instances request fallback (always empty)...');
      return from(
        this.serviceCaseOfflineService.getServiceCasesWithCount(
          pageSize,
          pageIndex,
          sortField,
          sortDirection,
          searchValue,
        ),
      );
    }
  }

  public getServiceCasesWithCount(
    populate?: boolean | ServiceCase,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
    filterQuery?: CaseAdvancedFilterQuery,
    staticFilters?: CaseAdvancedStaticFilter[],
  ): Observable<ApiResponse<ServiceCase[]>> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online service case instances request...');
      return this.getServiceCasesBase(
        populate,
        pageSize,
        pageIndex,
        sortDirection,
        sortField,
        searchValue,
        filterValue,
        filterQuery,
        staticFilters,
      ).pipe(map((res) => new ApiResponse<ServiceCase[]>(res.data, res.count)));
    } else {
      this.logger.debug()('Offline service case instances request fallback (always empty)...');
      return from(
        this.serviceCaseOfflineService.getServiceCasesWithCount(
          pageSize,
          pageIndex,
          sortField,
          sortDirection,
          searchValue,
          filterValue,
        ),
      );
    }
  }

  private getServiceCaseParams(populate?: boolean, shouldLimitActivities = false): HttpParams {
    let params = new HttpParams();
    if (populate !== undefined) {
      params = params.set('populate', populate.toString());
    }
    if (shouldLimitActivities) {
      params = params.set('maxConversations', MAX_NUMBER_OF_CONVERSATION_ACTIVITIES);
    }
    return params;
  }

  public sendPublicCaseRequest(payload: PublicCaseRequestPayload): Observable<ServiceCase> {
    return this.http
      .post<ApiResponse<ServiceCase>>(`${this.serviceCasesUrl}/public`, payload)
      .pipe(map((res) => res.data));
  }

  public addServiceCase({
    serviceCase,
    populate,
  }: {
    serviceCase: ServiceCasePayload;
    populate?: boolean;
  }): Observable<ServiceCase> {
    return this.http
      .post<ApiResponse<ServiceCase>>(this.serviceCasesUrl, serviceCase, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params: this.getServiceCaseParams(populate),
      })
      .pipe(map((res) => res.data));
  }

  public patchServiceCase({
    payload,
    populate,
    shouldLimitActivities,
  }: {
    payload: ServiceCasePayload;
    populate?: boolean;
    shouldLimitActivities?: boolean;
  }): Observable<ServiceCase> {
    return this.http
      .patch<ApiResponse<ServiceCase>>(`${this.serviceCasesUrl}/${payload._id}`, payload, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params: this.getServiceCaseParams(populate, shouldLimitActivities),
      })
      .pipe(map((res) => res.data));
  }

  public patchServiceCaseStatus(payload: {
    _id: string;
    status: ServiceCaseStatus;
  }): Observable<ServiceCase> {
    return this.patchServiceCase({ payload });
  }

  public fetchServiceCase({
    caseId,
    populate,
    shouldLimitActivities,
  }: {
    caseId: string;
    populate?: boolean;
    shouldLimitActivities?: boolean;
  }): Observable<ServiceCase> {
    return this.http
      .get<ApiResponse<ServiceCase>>(`${this.serviceCasesUrl}/${caseId}`, {
        params: this.getServiceCaseParams(populate, shouldLimitActivities),
      })
      .pipe(
        map((res) => res.data),
        takeUntil(this.destroyed$),
      );
  }

  public getServiceCase({
    caseId,
    populate,
    useCache,
    shouldLimitActivities,
  }: {
    caseId: string;
    populate?: boolean;
    useCache?: boolean;
    shouldLimitActivities?: boolean;
  }): Observable<ServiceCase> {
    if (this.connectivityService.getConnected()) {
      if (!useCache) {
        return this.fetchServiceCase({ caseId, populate, shouldLimitActivities });
      } else {
        const cacheKey = populate ? `${caseId}-populate` : caseId;
        if (!this.serviceCaseCache.has(cacheKey)) {
          const serviceCase$ = this.fetchServiceCase({
            caseId,
            populate,
            shouldLimitActivities,
          }).pipe(shareReplay());
          this.serviceCaseCache.set(cacheKey, serviceCase$);
          return serviceCase$;
        }
        return this.serviceCaseCache.get(cacheKey) as Observable<ServiceCase>;
      }
    } else {
      return from(this.serviceCaseOfflineService.getInstance(caseId, populate));
    }
  }

  public exportServiceCase(
    sCase: string,
    options?: CaseSinglePDFOptions,
  ): Observable<CaseExportResponse> {
    const url = `${this.serviceCasesUrl}/${sCase}/exportpdf`;

    return this.http
      .post<ApiResponse<CaseExportResponse>>(url, { options: options ? options : {} })
      .pipe(map((res) => res.data));
  }

  public deleteNoteActivity({
    serviceCaseId,
    noteActivityId,
    populate,
    shouldLimitActivities,
  }: {
    serviceCaseId: string;
    noteActivityId: string;
    populate?: boolean;
    shouldLimitActivities?: boolean;
  }): Observable<ServiceCase> {
    return this.http
      .delete<ApiResponse<ServiceCase>>(
        `${this.serviceCasesUrl}/${serviceCaseId}/activity/${noteActivityId}`,
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          params: this.getServiceCaseParams(populate, shouldLimitActivities),
        },
      )
      .pipe(map((res) => res.data));
  }

  // ========================  Ticket Categories  ===================================
  public getTicketTree(id?: string): Observable<TicketTree> {
    let params = new HttpParams();
    if (id) {
      params = params.set('tree_id', id);
    }

    return this.http
      .get<ApiResponse<TicketTree>>(this.serviceCasesUrl + '/trees', { params: params })
      .pipe(map((res) => res.data));
  }

  public updateTicketTree(tree: TicketTree) {
    const url = `${this.serviceCasesUrl}/trees`;
    return this.http.put<any>(url, tree).pipe(map((res) => res.data));
  }

  public nodeChangeUpdate(
    node: TicketTreeNode,
    path: string[],
    tree_id: string,
    dst_path?: string[],
    dst_index?: number,
  ): Observable<any> {
    const nodeChange = {
      path: path,
      dst_path: dst_path,
      dst_index: dst_index,
      name: node.name,
      question: node.question,
      children: node.children,
    };
    const url = `${this.serviceCasesUrl}/trees`;
    return this.http.patch<any>(url, { nodeChange: nodeChange }).pipe(map((res) => res.data));
  }

  public categoryChangeUpdate(categoryChange: any, tree_id: string): Observable<any> {
    const url = `${this.serviceCasesUrl}/trees`;
    return this.http
      .patch<any>(url, { categoryChange: categoryChange })
      .pipe(map((res) => res.data));
  }

  public deleteServiceCase(sCase: ServiceCase | string): Observable<ServiceCase> {
    const sCase_id = typeof sCase === 'string' ? sCase : sCase._id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.serviceCasesUrl}/${sCase_id}`;

    return this.http.delete<ServiceCase>(url, httpOptions);
  }

  public getTicketCategory(category: string): Observable<Category> {
    const url = `${this.serviceCasesUrl}/categories/${category}`;
    const params = new HttpParams();
    return this.http.get<any>(url, { params: params }).pipe(map((res) => res.data));
  }

  /** not implemented at the backend */
  public getTicketCategories(): Observable<Category[]> {
    return this.http.get<any>(this.serviceCasesUrl + '/categories').pipe(map((res) => res.data));
  }

  /** not implemented at the backend */
  public getTicketCategoriesByIds(ids: string[]): Observable<Category[]> {
    const params = new HttpParams().set('categories', JSON.stringify(ids));
    return this.http
      .get<any>(this.serviceCasesUrl + '/categories', { params: params })
      .pipe(map((res) => res.data));
  }

  public updateTicketCategory(category: Category): Observable<Category> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.serviceCasesUrl}/categories/${category._id}`;
    return this.http.put<any>(url, category, httpOptions).pipe(map((res) => res.data));
  }

  public deleteTicketCategory(category: Category | string): Observable<Category> {
    const category_id = typeof category === 'string' ? category : category._id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.serviceCasesUrl}/categories/${category_id}`;

    return this.http.delete<any>(url, httpOptions);
  }

  public getTicketTreeList(): Observable<TicketTree> {
    const params = new HttpParams();
    return this.http
      .get<ApiResponse<TicketTree>>(this.serviceCasesUrl + '/trees', { params: params })
      .pipe(map((res) => res.data));
  }

  // Communication
  public setAssetsSelection(assets: UnknownOr<ProductCompatibility>[]): void {
    this.assetsSelection.next(assets);
  }

  public getAssetsSelectionObservable(): Observable<UnknownOr<ProductCompatibility>[]> {
    return this.assetsSelection;
  }

  public setContactSelection(contact: UnknownOr<ContactCompatibility>): void {
    this.contactSelection.next(contact);
  }

  public getContactSelectionObservable(): Observable<UnknownOr<ContactCompatibility>> {
    return this.contactSelection;
  }

  public setSenderSelection(organization: UnknownOr<OrganizationCompatibility>): void {
    this.senderSelection.next(organization);
  }

  public getSenderSelectionObservable(): Observable<UnknownOr<OrganizationCompatibility>> {
    return this.senderSelection;
  }

  public move(sourceId: string, destinationId: string): Observable<ServiceCase> {
    return this.http
      .patch<
        ApiResponse<ServiceCase>
      >(`${this.serviceCasesUrl}/${sourceId}/move/${destinationId}`, null)
      .pipe(map((res) => res.data));
  }

  public undoMove(movedTicketId: string): Observable<unknown> {
    return this.http.patch(`${this.serviceCasesUrl}/${movedTicketId}/undomove`, null);
  }

  public setMessageActivityIsStatusIgnored(
    messageActivityId: string,
    isStatusIgnored: boolean,
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.serviceCasesUrl}/message-activity-status-ignored/${messageActivityId}/${isStatusIgnored}`,
      null,
    );
  }

  public getActivity(serviceCaseId: string, activityId: string): Observable<Activity> {
    return this.http
      .get<ApiResponse<Activity>>(`${this.serviceCasesUrl}/${serviceCaseId}/activity/${activityId}`)
      .pipe(map((res) => res.data));
  }

  public hasCaseBeenModifiedSince(serviceCaseId: string, lastModified?: Date): Observable<boolean> {
    if (!lastModified) return of(true);
    return this.http
      .get<ApiResponse<boolean>>(`${this.serviceCasesUrl}/${serviceCaseId}/modifiedSince`, {
        params: { date: lastModified.toString() },
      })
      .pipe(map((res) => res.data));
  }

  public clearCache(): void {
    this.serviceCaseCache.clear();
  }

  public findLastValidCategory(categories: any[] | undefined): Category | string | undefined {
    const category = findLast(categories, (cat) => cat && cat.category);
    return category ? category.category._id : undefined;
  }

  public ngOnDestroy(): void {
    this.clearCache();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
