import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FilterParams } from '@remberg/advanced-filters/common/main';
import {
  AssetsAdvancedFilterConfigKeys,
  AssetsFindManyInTreeQuery,
  AssetsFindManyInTreeResponse,
  AssetsPopulateEnum,
  AssetsSortFieldsEnum,
} from '@remberg/assets/common/main';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  PaginationParams,
  SearchParams,
  SortParams,
  filterEmptyProps,
} from '@remberg/global/ui';
import { EMPTY, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssetsTreeService {
  public readonly assetsUrl = `${API_URL_PLACEHOLDER}/assets/v1`;

  constructor(
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly httpClient: HttpClient,
  ) {}

  public findManyInTree({
    filterQuery,
    staticFilters,
    sortField,
    sortDirection,
    pageIndex,
    pageSize,
    searchQuery,
    populate,
    parentId,
  }: {
    populate?: AssetsPopulateEnum[];
    parentId?: string;
  } & SortParams<AssetsSortFieldsEnum> &
    PaginationParams &
    FilterParams<AssetsAdvancedFilterConfigKeys> &
    SearchParams): Observable<AssetsFindManyInTreeResponse> {
    if (!this.connectivityService.getConnected()) return EMPTY;

    const query: AssetsFindManyInTreeQuery = {
      page: pageIndex ?? 0,
      limit: pageSize ?? 20,
      search: searchQuery,
      sortDirection,
      sortField,
      filterObject: filterQuery,
      staticFilters,
      populate,
      parentId,
    };

    const {
      filterObject,
      staticFilters: newStaticFilters,
      populate: newPopulate,
      ...queryParams
    } = query;

    const params = new HttpParams({
      fromObject: {
        ...filterEmptyProps(queryParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(newStaticFilters && { staticFilters: JSON.stringify(newStaticFilters) }),
        ...(newPopulate && { populate: JSON.stringify(newPopulate) }),
      },
    });
    return this.httpClient.get<AssetsFindManyInTreeResponse>(`${this.assetsUrl}/tree`, { params });
  }
}
