import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OrganizationBasic } from '@remberg/crm/common/base';
import {
  Organization,
  OrganizationCompatibility,
  OrganizationRaw,
  OrganizationsCreateOneExternalBody,
  OrganizationsFindManyBasicResponse,
  OrganizationsFindManyCompatibilityResponse,
  OrganizationsFindManyQuery,
  OrganizationsFindManyResponse,
  OrganizationsPopulateTypeEnum,
  OrganizationsUpdateOneBody,
  organizationRawToOrganizationBasic,
} from '@remberg/crm/common/main';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
  filterEmptyProps,
} from '@remberg/global/ui';
import { Observable, catchError, from, map, of, throwError } from 'rxjs';
import { OrganizationsFindManyWithCountCompatibilityParams } from './organizations.definitions';
import {
  ORGANIZATIONS_OFFLINE_SERVICE,
  OrganizationOfflineServiceInterface,
} from './organizations.offline.service.interface';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private readonly organizationsUrl = `${API_URL_PLACEHOLDER}/organizations/v1`;

  constructor(
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(ORGANIZATIONS_OFFLINE_SERVICE)
    private readonly organizationOfflineService: OrganizationOfflineServiceInterface,
    private readonly http: HttpClient,
    private readonly logger: LogService,
  ) {}

  public findOne(
    id: string,
    populate?: Record<OrganizationsPopulateTypeEnum, boolean>,
  ): Observable<Organization> {
    let httpParams = new HttpParams();
    if (populate?.ownerContact)
      httpParams = httpParams.set(
        'populate',
        JSON.stringify([OrganizationsPopulateTypeEnum.OWNER_CONTACT]),
      );

    return this.http.get<Organization>(`${this.organizationsUrl}/${id}`, {
      params: httpParams,
    });
  }

  public findOneBasic(organizationId: string): Observable<OrganizationBasic | undefined> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()(`Getting one organization basic in offline mode ${organizationId}`);
      return from(this.organizationOfflineService.tryGetInstance(organizationId)).pipe(
        map(organizationRawToOrganizationBasic),
      );
    }

    return this.http
      .get<OrganizationBasic>(`${this.organizationsUrl}/basic/${organizationId}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (
            error.status === HttpStatusCode.NotFound ||
            error.status === HttpStatusCode.Forbidden
          ) {
            return of(undefined);
          }
          return throwError(() => error);
        }),
      );
  }

  public findOneRaw(organizationId: string): Observable<OrganizationRaw | undefined> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()(`Getting one organization raw in offline mode ${organizationId}`);
      return from(this.organizationOfflineService.tryGetInstance(organizationId));
    }

    return this.http
      .put<OrganizationRaw[]>(`${this.organizationsUrl}/sync`, { ids: [organizationId] })
      .pipe(map((result) => result[0]));
  }

  public findOneRawOrThrow(organizationId: string): Observable<OrganizationRaw> {
    return this.findOneRaw(organizationId).pipe(
      map((organization) => {
        if (!organization) {
          throw new Error('OrganizationRaw not found');
        }
        return organization;
      }),
    );
  }

  public findOneCompatibility(organizationId: string): Observable<OrganizationCompatibility> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()(
        `Getting one organization compatibility in offline mode ${organizationId}`,
      );
      return from(this.organizationOfflineService.getInstance(organizationId));
    }

    return this.http.get<OrganizationCompatibility>(
      `${this.organizationsUrl}/compatibility/${organizationId}`,
    );
  }

  public findManyWithCountBasic(
    params: OrganizationsFindManyQuery,
  ): Observable<OrganizationsFindManyBasicResponse> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()('Getting many organizations basic with count in offline mode');
      return from(
        this.organizationOfflineService.getManyWithCount({
          pageSize: params.limit,
          pageIndex: params.page,
          sortDirection: params.sortDirection,
          sortField: params.sortField,
          searchQuery: params.search,
          filterValue: [],
        }),
      ).pipe(
        map(({ count, data }) => ({
          count: count as number,
          organizations: data.map((org) => organizationRawToOrganizationBasic(org)),
        })),
      );
    }

    const { filterObject, staticFilters, ...findManyParams } = params;
    const httpParams = new HttpParams({
      fromObject: {
        ...filterEmptyProps(findManyParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
      },
    });
    return this.http.get<OrganizationsFindManyBasicResponse>(`${this.organizationsUrl}/basic`, {
      params: httpParams,
    });
  }

  public findManyWithCountCompatibility(
    params: OrganizationsFindManyWithCountCompatibilityParams,
  ): Observable<ApiResponse<OrganizationCompatibility[]>> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()('Getting many organizations with count in offline mode');
      return from(this.organizationOfflineService.getManyWithCount(params));
    }
    const { filterValue, ...findManyParams } = params;
    const assetId = filterValue.find((f) => f.identifier === 'asset')?.value;
    const httpParams = new HttpParams({
      fromObject: {
        ...filterEmptyProps(findManyParams),
        ...(assetId && { assetId }),
      },
    });

    return this.http
      .get<OrganizationsFindManyCompatibilityResponse>(`${this.organizationsUrl}/compatibility`, {
        params: httpParams,
      })
      .pipe(map(({ count, organizations }) => ({ count, data: organizations })));
  }

  public findMany(params: OrganizationsFindManyQuery): Observable<OrganizationsFindManyResponse> {
    const { filterObject, staticFilters, populate, ...findManyParams } = params;
    const httpParams = new HttpParams({
      fromObject: {
        ...filterEmptyProps(findManyParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
        ...(populate && { populate: JSON.stringify(populate) }),
      },
    });

    return this.http.get<OrganizationsFindManyResponse>(`${this.organizationsUrl}`, {
      params: httpParams,
    });
  }

  public findManyByIdsCompatibility(
    organizationIds: string[],
  ): Observable<OrganizationCompatibility[]> {
    return this.http.put<OrganizationCompatibility[]>(`${this.organizationsUrl}/compatibility`, {
      organizationIds,
    });
  }

  // TODO: Remove after work orders 1 is deprecated and removed
  public findManyByIdsBasic(organizationIds: string[]): Observable<OrganizationBasic[]> {
    return this.http.put<OrganizationBasic[]>(`${this.organizationsUrl}/basic`, {
      organizationIds,
    });
  }

  public createOneExternal(
    organization: OrganizationsCreateOneExternalBody,
  ): Observable<Organization> {
    return this.http.post<Organization>(`${this.organizationsUrl}/external`, organization);
  }

  public updateLogo(id: string, image: File): Observable<Organization> {
    const formData = new FormData();
    formData.append('image', image, encodeURIComponent(image.name));
    return this.http.patch<Organization>(`${this.organizationsUrl}/${id}/logo`, formData);
  }

  public updateOne(id: string, organization: OrganizationsUpdateOneBody): Observable<Organization> {
    return this.http.patch<Organization>(`${this.organizationsUrl}/${id}`, organization);
  }

  public deleteOne(id: string): Observable<void> {
    return this.http.delete<void>(`${this.organizationsUrl}/external/${id}`);
  }

  public removeLogo(id: string): Observable<Organization> {
    const updateOneOrganization: OrganizationsUpdateOneBody = { logoFileId: null };
    return this.http.patch<Organization>(`${this.organizationsUrl}/${id}`, updateOneOrganization);
  }
}
