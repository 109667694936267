import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { ApiResponse, OfflineService } from '@remberg/global/ui';
import { AssetTypeRawOffline } from './asset-type-raw-offline.interface';
import { AssetTypesFindManyOfflineQueryInterface } from './asset-types.definitions';

export interface AssetTypesOfflineServiceInterface
  extends OfflineService<AssetTypeRawOffline, AdvancedFilterQuery<never>, AdvancedFilter<never>> {
  getAssetTypesWithCount(
    options: AssetTypesFindManyOfflineQueryInterface,
  ): Promise<ApiResponse<AssetTypeRawOffline[]>>;

  getManyByIds(ids: string[]): Promise<AssetTypeRawOffline[]>;
}

export const ASSET_TYPES_OFFLINE_SERVICE = new InjectionToken<AssetTypesOfflineServiceInterface>(
  'Token for injecting the asset types offline service without circular dependencies',
);
