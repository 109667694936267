import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { LogService } from '@remberg/global/ui';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonLoaderComponent {
  /** materialColor defines the default Button theme */
  @Input() public materialColor: ThemePalette = 'primary';

  /** buttonStyle: material style */
  @Input() public buttonStyle:
    | 'mat-raised-button'
    | 'mat-flat-button'
    | 'mat-stroked-button'
    | 'mat-button'
    | ''
    | string = 'mat-raised-button';

  /** disabled: disabled state and not clickable, needs to handle overwrite colors */
  @Input() public disabled = false;

  /** loading: defines main status */
  @Input() public loading = false;

  /** loading: defines main status */
  @Input() public isErrorState = false;

  /** text: equals label */
  @Input() public text?: string;

  /** datatTestId: test id for unit tests */
  @Input() public dataTestId?: string;

  /** icon: adds material icon to button */
  @Input() public icon?: string;

  /** additionalClass: adds class for customization */
  @Input() public additionalClass = '';

  /** additionalClassIsSmallView: adds class for customization for responsive views*/
  @Input() public additionalClassIsSmallView = '';

  /** overrideNgStyle: overrides relative default relative positioning of button */
  @Input() public overrideNgStyle?: Record<string, string>;

  /** showTextOnLoading: Show loader and text */
  @Input() public showTextOnLoading = false;

  /** spinnerSize: Customize spinne size for smaller or larger buttons */
  @Input() public spinnerSize?: number;

  protected readonly defaultSpinnerSize = 18;

  /** showTextOnLoading: action emitter when clicked */
  @Output() public action = new EventEmitter<MouseEvent | KeyboardEvent>();

  /** dataProductTourId: used in the application tour */
  public dataProductTourId = 'action-button';

  constructor(private readonly logger: LogService) {}

  protected loaderClick(event: MouseEvent): void {
    if (!this.loading && !this.disabled) {
      this.logger.debug()('Register loader button click.');
      this.action.emit(event);
    }
  }
}
