import { InjectionToken } from '@angular/core';
import { AdvancedFilter } from '@remberg/advanced-filters/common/main';
import { Asset, AssetsFindManyResponse } from '@remberg/assets/common/main';
import { OfflinePopulateType, OfflineService } from '@remberg/global/ui';
import { AssetRawOffline } from './asset-raw-offline.interface';
import {
  AssetsFindManyOfflineQueryInterface,
  AssetsOfflineAdvancedFilterConfigKeys,
  AssetsOfflineAdvancedFilterQuery,
} from './assets.definitions';

export interface AssetsOfflineServiceInterface
  extends OfflineService<
    AssetRawOffline,
    AssetsOfflineAdvancedFilterQuery,
    AdvancedFilter<AssetsOfflineAdvancedFilterConfigKeys>
  > {
  findMany(options: AssetsFindManyOfflineQueryInterface): Promise<AssetsFindManyResponse>;

  findManyByIds(ids: string[], populate?: OfflinePopulateType): Promise<Asset[]>;

  findOne(id: string | Asset, populate?: OfflinePopulateType): Promise<Asset>;
}

export const ASSETS_OFFLINE_SERVICE = new InjectionToken<AssetsOfflineServiceInterface>(
  'Token for injecting the assets offline service without circular dependencies',
);
