import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { DynamicProgressBarConfig, LogService } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';

export async function migrateToV29(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()('Starting DB migration to V29...');

  await removeAssetsV1Tables(db, logger);

  await db.executeSql('PRAGMA user_version = 29 ;', []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()('Completed DB migration to V29.');
}

async function removeAssetsV1Tables(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  try {
    await removeTable(db, logger, 'assets');
  } catch (error) {
    logger.error()(`Error removing assets table`, error);
  }
  try {
    await removeTable(db, logger, 'assetTypes');
  } catch (error) {
    logger.error()(`Error removing assetTypes table`, error);
  }
}

async function removeTable(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  tableName: string,
): Promise<void> {
  try {
    const tableExistsQuery = await db.executeSql(
      `SELECT name FROM sqlite_master WHERE type='table' AND name='${tableName}';`,
      [],
    );

    if (tableExistsQuery.rows?.item(0)?.name === tableName) {
      logger.debug()(`FOUND ${tableName} table, removing...`);

      await db.executeSql(`DROP TABLE IF EXISTS ${tableName}`, []);
    } else {
      logger.debug()(`${tableName} table does not exist. Skipping migration.`);
    }
  } catch (error) {
    logger.error()(`Error during ${tableName} table existence check`, error);
  }
}
