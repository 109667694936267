import { valuesOf } from '@remberg/global/common/core';
import { TicketActivityType } from '@remberg/tickets/common/main';
import { Ticket2StatusEnum } from '../enums';

export const CONVERSATIONS_ACTIVITIES = [
  TicketActivityType.NOTE,
  TicketActivityType.OUTBOUND_EMAIL_MESSAGE,
  TicketActivityType.INBOUND_EMAIL_MESSAGE,
  TicketActivityType.PORTAL_MESSAGE,
  TicketActivityType.CASE_MOVED,
  TicketActivityType.CASE_MOVED_UNDO,
];

export const COMPLETED_STATUSES = new Set<Ticket2StatusEnum>([
  Ticket2StatusEnum.CLOSED,
  Ticket2StatusEnum.SOLVED,
  Ticket2StatusEnum.MOVED,
]);

export const BEING_PROCESSED_STATUSES = new Set<Ticket2StatusEnum>([
  Ticket2StatusEnum.NEW,
  Ticket2StatusEnum.OPEN,
  Ticket2StatusEnum.PENDING_INTERNAL,
]);
export const CLOSED_PORTAL_STATUSES = new Set<Ticket2StatusEnum>([
  Ticket2StatusEnum.SOLVED,
  Ticket2StatusEnum.CLOSED,
]);

export const STATUSES_EXCLUDED_FROM_RESOLUTION_TIME = new Set<Ticket2StatusEnum>([
  ...COMPLETED_STATUSES,
  Ticket2StatusEnum.PENDING_EXTERNAL,
]);

export const STATUSES_INCLUDED_IN_TIME_TO_RESOLUTION: Ticket2StatusEnum[] = valuesOf(
  Ticket2StatusEnum,
).filter((status) => !STATUSES_EXCLUDED_FROM_RESOLUTION_TIME.has(status));
