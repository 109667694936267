import { RembergDateTime } from '../definitions';
import { DATE_REGEX, ISO_DATE_REGEX, REMBERG_DATE_TIME_REGEX } from './regex';

export const isRembergDateString = (input: string): boolean => RegExp(DATE_REGEX).test(input);
export const isISODateString = (input: string): boolean => RegExp(ISO_DATE_REGEX).test(input);
export const isRembergDateTimeString = (input: string): input is RembergDateTime =>
  RegExp(REMBERG_DATE_TIME_REGEX).test(input);

export const isRembergTimestamp = (rembergTimestamp: string): boolean => {
  if (rembergTimestamp && ISO_DATE_REGEX.test(rembergTimestamp)) {
    const date = new Date(rembergTimestamp);
    // Profs that rembergTimestamp is a valid UTC date
    return !isNaN(date.valueOf());
  }
  return false;
};
