import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SanitizeUrlPipe } from '../../pipes/sanitize-url.pipe';

@Component({
  selector: 'app-feature-restricted',
  templateUrl: './feature-restricted.component.html',
  styleUrls: ['./feature-restricted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SanitizeUrlPipe],
})
export class FeatureRestrictedComponent {}
